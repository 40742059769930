import React, {FC, useEffect, useRef, useState} from 'react';
import {useController} from 'react-hook-form';
import {BaseInputProps} from '../interfaces/BaseInputsProps';
import {IonActionSheet, isPlatform} from '@ionic/react';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {base64ToFile} from '../utils/base64ToBlobConverter';

type AvatarImageInputProps = BaseInputProps;

const AvatarImageInput: FC<AvatarImageInputProps> = ({control, fieldName = '', rules}) => {
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const imageInput = useRef<any>(null);
  const {
    field: {onChange, value, onBlur},
  } = useController({name: fieldName, rules, control});

  useEffect(() => {
    setIsDisable(isPlatform('android'));
  }, []);

  const handleAndroidCamera = async (source: CameraSource) => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      source: source,
      resultType: CameraResultType.Base64,
    });
    if (!image) return;
    const file = base64ToFile(image.base64String!);

    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${fileName}`;

    onChange({file, fileExt, filePath});
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      throw new Error('Sie müssen ein Bild zum Hochladen auswählen.');
    }

    const file = event.target.files[0];
    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${fileName}`;

    onChange({file, fileExt, filePath});
  };

  const handleOnClick = () => {
    if (isPlatform('android')) {
      setIsOpen(true);
    } else {
      imageInput.current?.click();
    }
  };

  return (
    <div className='w-full mb-5 ml-3 avatar'>
      <div className='flex ml-7 w-28 rounded-3xl border border-white' aria-live='polite'>
        <img
          alt='Avatar'
          src={URL.createObjectURL(value.file)}
          className='image rounded-3xl w-28 '
        />
        <div className='flex'>
          <div className='flex items-center w-32 pl-5' onClick={() => isDisable && handleOnClick()}>
            <label
              className='button primary block text-[12px] text-lightGreen underline'
              htmlFor='single'
            >
              Foto ändern
            </label>
            <IonActionSheet
              isOpen={isOpen}
              cssClass={'custom-action-sheet'}
              onDidDismiss={() => setIsOpen(false)}
              buttons={[
                {
                  text: 'Fotomediathek',
                  handler: () => handleAndroidCamera(CameraSource.Photos),
                },
                {
                  text: 'Foto aufnehmen',
                  handler: () => handleAndroidCamera(CameraSource.Camera),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <input
        className='hidden'
        type='file'
        id='single'
        ref={imageInput}
        accept='.png, .jpg, .jpeg'
        onBlur={onBlur}
        onChange={handleChange}
        disabled={isDisable}
      />
    </div>
  );
};

export default AvatarImageInput;

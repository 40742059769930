import React, {FC, useEffect} from 'react';
import {IonContent, IonHeader, IonPage} from '@ionic/react';
import {useSetRecoilState} from 'recoil';
import IonLoadingSuspense from '../../components/IonLoadingSuspense';
import {isBottomTabBarHiddenAtom} from '../../atoms/bottomTabBarAtom';
import {ShoppingCartProductsList} from './ShoppingCartProducts.list';
import ShoppingCartDeleteAllButton from './ShoppingCartDeleteAll.button';
import {BackButton} from '../../components/Buttons/BackButton';
import AppToolbar from '../../components/AppToolbar';

const ShoppingCart: FC = () => {
  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          titleText='Einkaufswagen'
          rightSlot1={
            <IonLoadingSuspense>
              <ShoppingCartDeleteAllButton />
            </IonLoadingSuspense>
          }
        />
      </IonHeader>
      <IonContent className='w-full h-full bg-darkGreen'>
        <IonLoadingSuspense>
          <ShoppingCartProductsList />
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};

export default ShoppingCart;

import React from 'react';
import {ComponentProps, FC} from 'react';
import {useIonRouter} from '@ionic/react';
import Button from './Button';

type ButtonLinkProps = {
  routerLink: string;
  routerDirection?: string;
} & ComponentProps<typeof Button>;

const ButtonLink: FC<ButtonLinkProps> = (props) => {
  const {routerDirection, routerLink, text, ...other} = props;
  const router = useIonRouter();
  return (
    <Button
      text={text}
      onClick={() => {
        router.push(routerLink, routerDirection);
      }}
      {...other}
    />
  );
};

export default ButtonLink;

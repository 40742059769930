import React, {FC, useState} from 'react';
import {IonIcon} from '@ionic/react';
import emptyCart from '../../static/empty_product_cart.svg';
import cart from '../../static/product_cart.svg';
import cartsService from '../../services/cartsService';
import {useSetRecoilState} from 'recoil';
import {cartProductsQuantity} from '../../selectors/cartSelector';
import {tmpCartProductsQuantity} from '../../atoms/tmpCartSelector';

type Props = {
  productId: number;
  isInCart: boolean;
  isUserAuth: boolean;
  isInNonLoggedUserCart?: boolean;
  className: string;
};

export const ShoppingCartButton: FC<Props> = ({
  productId,
  isInCart,
  isUserAuth,
  isInNonLoggedUserCart,
  className,
}) => {
  const [inCart, setInCart] = useState<boolean>(isInCart);
  const setCartProductsCount = useSetRecoilState(cartProductsQuantity);
  const [inNonLoggedCart, setInNonLoggedCart] = useState<boolean>(isInNonLoggedUserCart!);
  const setNonLoggegCartCount = useSetRecoilState(tmpCartProductsQuantity);

  const handleAddToCart = async (productId: number, e: any) => {
    if (isUserAuth) {
      if (!inCart) {
        try {
          await cartsService.insertProductToCurrentUserCart(productId);
          setInCart(true);

          setCartProductsCount((prev: number) => prev + 1);
        } catch (error) {
          setInCart(false);

          setCartProductsCount((prev: number) => prev - 1);
        }
        return;
      }
      await cartsService.removeProductFromCurrentUserCart(productId);
      setInCart(false);

      setCartProductsCount((prev: number) => prev - 1);
      return;
    }

    if (e.target) {
      if (!inNonLoggedCart) {
        setInNonLoggedCart(true);
        addNonLoggedItemToLocalStorage(productId);
        setNonLoggegCartCount((prev: any) => prev + 1);
        return;
      }
      setInNonLoggedCart(false);
      removeNonLoggedItemFromLocalStorage(productId);
      setNonLoggegCartCount((prev: any) => prev - 1);
    }
  };

  const addNonLoggedItemToLocalStorage = (productId: any) => {
    const nonLoggedCartString = localStorage.getItem('nonLoggedCart');
    const nonLoggedCart = nonLoggedCartString ? JSON.parse(nonLoggedCartString) : [];

    nonLoggedCart.push(productId);

    localStorage.setItem('nonLoggedCart', JSON.stringify(nonLoggedCart));
  };

  const removeNonLoggedItemFromLocalStorage = (productId: any) => {
    const nonLoggedCartString = localStorage.getItem('nonLoggedCart');
    const nonLoggedCart = nonLoggedCartString ? JSON.parse(nonLoggedCartString) : [];

    const updatedCart = nonLoggedCart.filter((x: any) => x !== productId);

    localStorage.setItem('nonLoggedCart', JSON.stringify(updatedCart));
  };

  return (
    <IonIcon
      className={className}
      color='danger'
      icon={isUserAuth ? (inCart ? cart : emptyCart) : inNonLoggedCart ? cart : emptyCart}
      onClick={(e: any) => handleAddToCart(productId, e)}
    />
  );
};

import React, {FC, useEffect} from 'react';
import {IonContent, IonHeader, IonPage} from '@ionic/react';
import {useSetRecoilState} from 'recoil';
import {SearchButton} from '../../../components/Buttons/SearchButton';
import {CartButton} from '../../../components/Buttons/CartButton';
import HeaderSubCategories from '../../../components/HeaderSubCategories';
import FilterDetailModal from '../../../components/Filter/FilterDetailModal';
import FilterModal from '../../../components/Filter/FIlterModal';
import OnSaleProductsList from './SaleProducts.list';
import IonLoadingSuspense from '../../../components/IonLoadingSuspense';
import {choosenCategoryIdAtom} from '../../../atoms/choosenCategoryIdAtom';
import HeaderCategories from '../../../components/HeaderCategories';
import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';
import AppToolbar from '../../../components/AppToolbar';

const OnSaleProducts: FC = () => {
  const setChoosenCategoryId = useSetRecoilState(choosenCategoryIdAtom);
  const setChoosenSubcategoryId = useSetRecoilState(choosenSubcategoryIdAtom);

  useEffect(() => {
    return () => {
      setChoosenCategoryId(null);
      setChoosenSubcategoryId(null);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar rightSlot2={<SearchButton />} rightSlot3={<CartButton />} />
        <HeaderCategories />
        <HeaderSubCategories />
      </IonHeader>
      <IonContent>
        <IonLoadingSuspense>
          <OnSaleProductsList />
        </IonLoadingSuspense>
        <FilterDetailModal />
        <IonLoadingSuspense>
          <FilterModal />
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};

export default OnSaleProducts;

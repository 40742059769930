import React, {FC} from 'react';

type Props = {
  boughtDate: any;
};

export const SoldGreenBarElement: FC<Props> = ({boughtDate}) => {
  return (
    <div
      className='flex justify-center items-center w-full h-6 bg-primary rounded-br-xl text-xs
      desktop:text-sm font-medium text-white'
    >
      {boughtDate}
    </div>
  );
};

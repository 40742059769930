import {IonContent, IonHeader, IonPage} from '@ionic/react';
import React, {FC, useEffect, useState} from 'react';
import IonLoadingSuspense from '../../../components/IonLoadingSuspense';
import {SearchButton} from '../../../components/Buttons/SearchButton';
import HistoryProductsTabs from './HistoryProducts.tabs';
import {isBottomTabBarHiddenAtom} from '../../../atoms/bottomTabBarAtom';
// eslint-disable-next-line camelcase
import {useRecoilRefresher_UNSTABLE, useSetRecoilState} from 'recoil';
import FilterDetailModal from '../../../components/Filter/FilterDetailModal';
import FilterModal from '../../../components/Filter/FIlterModal';
import SoldProductsTab from './SoldProducts/SoldProducts.tab';
import BoughtProductsTab from './BoughtProducts/BoughtProducts.tab';
import AppToolbar from '../../../components/AppToolbar';
import {BackButton} from '../../../components/Buttons/BackButton';
import {
  getBoughtProductsSelector,
  getSoldProductsSelector,
} from '../../../selectors/historyProductsSelectors';

const HistoryPage: FC = () => {
  const sold = 'Verkauft';
  const bought = 'Gekauft';

  const [activeTab, setActiveTab] = useState(bought);
  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);
  const refreshSoldProducts = useRecoilRefresher_UNSTABLE(getSoldProductsSelector);
  const refreshBoughtProducts = useRecoilRefresher_UNSTABLE(getBoughtProductsSelector);
  refreshSoldProducts();
  refreshBoughtProducts();

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          titleText='Geschichte'
          rightSlot1={<SearchButton />}
        />
      </IonHeader>
      <IonContent>
        <IonLoadingSuspense>
          <>
            <div className='flex justify-center pt-1'>
              <HistoryProductsTabs
                sold={sold}
                bought={bought}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div className='mobile:-mt-1 pb-5'>
              {activeTab == sold && <SoldProductsTab />}
              {activeTab == bought && <BoughtProductsTab />}
            </div>
          </>
        </IonLoadingSuspense>
        <FilterDetailModal />
        <IonLoadingSuspense>
          <FilterModal />
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};

export default HistoryPage;

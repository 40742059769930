import {IonContent, IonModal, useIonRouter} from '@ionic/react';
import React, {useRef} from 'react';
import Logo from './Logo';
import Button from './Buttons/Button';
import {supabase} from '../supabaseClient';
import {useAuth} from '../contexts/Auth';
import {useSetRecoilState} from 'recoil';
import {choosenCategoryIdAtom} from '../atoms/choosenCategoryIdAtom';

type ModalProps = {
  isOpen: boolean;
};

const AgeConsentModal: React.FC<ModalProps> = ({isOpen}) => {
  const {user} = useAuth();
  const modal = useRef<HTMLIonModalElement>(null);
  const setChosenCategoryId = useSetRecoilState(choosenCategoryIdAtom);
  const router = useIonRouter();

  const confirmHandler = () => {
    if (user?.id === undefined) {
      modal.current?.dismiss();
      router.push('/login');
      return;
    }
    const insertData = async () => {
      const {error} = await supabase
        .from('profiles')
        .update({age_consent: true})
        .eq('user_id', user?.id);
      if (error != null) {
        throw error.message;
      }
    };
    insertData();
    setChosenCategoryId(6);
    router.push('/products');
    modal.current?.dismiss();
  };

  const cancelHandler = () => {
    setChosenCategoryId(0);
    modal.current?.dismiss();
  };

  return (
    <IonModal ref={modal} isOpen={isOpen}>
      <IonContent className='ion-padding'>
        <div className='flex h-[30%] desktop:h-[40%] justify-center'>
          <Logo />
        </div>
        <div className='flex h-[25%] mx-auto items-center text-center text-xl desktop:w-[60%]'>
          Mit drücken auf „Weiter“ erkläre ich, dass ich volljährig bin und dass die Gesetze des
          Landes, in dem ich mich befinde, es mir erlauben, nicht jugendfreie Inhalte anzusehen.
        </div>
        <div className='h-[15%]' />
        <div className='flex justify-center'>
          <Button text='Weiter' onClick={confirmHandler}></Button>
          <Button text='Abbrechen' onClick={cancelHandler}></Button>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AgeConsentModal;

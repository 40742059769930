import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {getBoughtProductsSelector} from '../../../../selectors/historyProductsSelectors';

import BoughtProductElement from './BoughtProduct.element';

const BoughtProductsTab: FC = () => {
  const currentOwnerBoughtProducts = useRecoilValue(getBoughtProductsSelector);
  return (
    <>
      {currentOwnerBoughtProducts.map((cart: any) =>
        cart.map((product: any) => (
          <div key={product.productId}>
            <BoughtProductElement {...product} />
          </div>
        )),
      )}
    </>
  );
};

export default BoughtProductsTab;

import React, {FC} from 'react';
import BIonForm from '../components/BIonForm';
import TextInput from '../components/TextInput/TextInput';
import BaseFormProps from '../interfaces/BaseFormProps';
import {FormField} from '../interfaces/FormField';
import {SignUpData} from '../interfaces/SignUpData';
import regularExpressions from '../utils/regularExpressions';

const SignUpForm: FC<BaseFormProps<SignUpData>> = ({onSubmit}) => {
  const fields: FormField[] = [
    {
      name: 'firstName',
      component: <TextInput label='Vorname' />,
      options: {required: 'This field is required!'},
    },
    {
      name: 'lastName',
      component: <TextInput label='Nachname' />,
      options: {required: 'This field is required!'},
    },
    {
      name: 'nickName',
      component: <TextInput label='Nick Name (Angezeigter Name)' />,
      options: {required: 'Dieses Feld ist erforderlich!'},
    },
    {
      name: 'email',
      component: <TextInput label='Email' />,
      options: {
        required: 'This field is required!',
        pattern: {
          message: 'Die eingegebene E-Mail-Adresse ist falsch.',
          value: regularExpressions.email,
        },
      },
    },
    {
      name: 'password',
      component: <TextInput label='Passwort' type='password' />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
        pattern: {
          message: `Mindestens 8 Zeichen, mindestens ein Großbuchstabe 
          Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen.`,
          value: regularExpressions.password,
        },
      },
    },
  ];

  return (
    <BIonForm
      fields={fields}
      submitBtnText='Anmeldung'
      onSubmit={onSubmit}
      customCss='mx-auto w-full tablet:w-3/5 desktop:w-3/5'
    />
  );
};

export default SignUpForm;

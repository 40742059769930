import React, {useEffect} from 'react';
import {IonContent, IonHeader, IonPage, IonText} from '@ionic/react';
import {useAuth} from '../../contexts/Auth';
import Logo from '../../components/Logo';
import {RecoverPasswordData} from '../../interfaces/RecoverPasswordData';
import useToast from '../../hooks/customToast';
import RecoverPasswordForm from '../../forms/RecoverPassword.form';
import {BackButton} from '../../components/Buttons/BackButton';
import {useSetRecoilState} from 'recoil';
import {isBottomTabBarHiddenAtom} from '../../atoms/bottomTabBarAtom';
import AppToolbar from '../../components/AppToolbar';

const RecoverPassword = () => {
  const {recoverPassword} = useAuth();
  const presentToast = useToast();

  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  const onSubmit = async (recoverPasswordData: RecoverPasswordData) => {
    const {error} = await recoverPassword(recoverPasswordData.email);

    if (error) {
      await presentToast(error.message, 'error');
      return;
    }

    await presentToast(
      'Der Link zum Zurücksetzen des Passworts wurde an die angegebene E-Mail-Adresse gesendet!',
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar leftSlot1={<BackButton />} titleText='Passwort zurücksetzen' />
      </IonHeader>
      <IonContent>
        <div className='w-full flex flex-col justify-start space-y-5 mt-10'>
          <Logo />
          <IonText
            className='mx-auto w-[90%] tablet:w-1/2 desktop:w-1/2
          text-center font-light text-sm'
          >
            Du hast dein Passwort vergessen? <br /> Kein Problem! Bitte gib deine Mail-Adresse ein
            und wenn du in unserem System registriert bist, bekommst du einen Link zugeschickt, um
            dein Passwort zurückzusetzen.
          </IonText>
          <RecoverPasswordForm onSubmit={onSubmit} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RecoverPassword;

import React, {FC, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useAuth} from '../../contexts/Auth';
import {Chat} from '../../interfaces/Chat';
import {lastestMessageIdFromDbAtom} from '../../selectors/messagesSelectors';
import {messagesService} from '../../services/messagesService';
import {InboxItem} from './InboxItem';

interface ChatsListProps {}

export const ChatsList: FC<ChatsListProps> = ({}) => {
  const lastestMessageIdFromDb = useRecoilValue(lastestMessageIdFromDbAtom);
  const [chats, setChats] = useState<Chat[]>();
  const {user} = useAuth();

  const fetchChats = async () => {
    await messagesService.getChats(user!.id).then(setChats);
  };

  useEffect(() => {
    fetchChats();
  }, [user, lastestMessageIdFromDb]);

  return (
    <div className='mx-auto w-full tablet:w-4/5 desktop:w-5/12'>
      {chats && chats.length > 0 ? (
        chats.map((x) => <InboxItem key={x.id} {...x} />)
      ) : (
        <div className='flex py-20 justify-center mobile:w-5/6 mx-auto'>
          In deinem Posteingang sind aktuell keine Nachrichten.
        </div>
      )}
    </div>
  );
};

import React, {FC, useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {IonChip, IonLabel, IonToolbar} from '@ionic/react';
import IonLoadingSuspense from '../../../components/IonLoadingSuspense';
import {allSubcategoriesSelector} from '../../../selectors/subcategoriesSelectors';
import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';

const FavouritesProductsSubCategories: FC = () => {
  return (
    <IonToolbar color='primary' className='h-14'>
      <IonLoadingSuspense>
        <>
          <div className='w-full h-[1px] bg-white' />
          <FavouritesProductsSubCategoriesItems />
        </>
      </IonLoadingSuspense>
    </IonToolbar>
  );
};

export default FavouritesProductsSubCategories;

const FavouritesProductsSubCategoriesItems: FC = () => {
  const subCategories = useRecoilValue(allSubcategoriesSelector);
  const [choosenSubCategoryId, setChoosenSubCategoryId] = useRecoilState(choosenSubcategoryIdAtom);

  const handleSubcategoryChoice = (subCategoryId: number) => {
    setChoosenSubCategoryId(subCategoryId);
  };

  useEffect(() => {
    if (subCategories == null || subCategories.length == 0) {
      setChoosenSubCategoryId(null);
      return;
    }
    setChoosenSubCategoryId(subCategories[0].id);
    return () => {
      setChoosenSubCategoryId(null);
    };
  }, []);

  return (
    <div
      className='flex overflow-x-auto w-full py-1 tablet:py-1.5 tablet:px-2
    desktop:py-2 desktop:px-2'
    >
      {subCategories &&
        subCategories.map((x) => (
          <IonChip
            key={x.id}
            className='border-1 flex-shrink-0 border-white pointer-events-auto'
            onClick={() => handleSubcategoryChoice(x.id)}
            disabled={choosenSubCategoryId !== x.id}
          >
            <IonLabel>{x.name}</IonLabel>
          </IonChip>
        ))}
    </div>
  );
};

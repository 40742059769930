import React, {FC, useEffect, useState} from 'react';
import {IonContent, IonHeader, IonPage, useIonRouter} from '@ionic/react';
import './ProfileAlert.css';
import AppToolbar from '../../components/AppToolbar';
import Logo from '../../components/Logo';
import {useAuth} from '../../contexts/Auth';
import {supabase} from '../../supabaseClient';
import ButtonLink from '../../components/Buttons/ButtonLink';

const VerifySellerAccount: FC = () => {
  const {user} = useAuth();
  const router = useIonRouter();

  const [sellerActive, setSellerActive] = useState<boolean | null>(null);

  useEffect(() => {
    const failureTimer = setTimeout(() => {
      setSellerActive(false);
    }, 30000);

    const subscription = supabase
      .from(`profiles:user_id=eq.${user!.id}`)
      .on('UPDATE', (payload) => {
        setSellerActive(payload.new.charges_enabled);
        if (payload.new.charges_enabled === true) {
          clearTimeout(failureTimer);
          setTimeout(() => {
            router.push('/dashboard');
          }, 3000);
        }
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar />
      </IonHeader>
      <IonContent>
        <div className='h-full flex flex-col'>
          <div className='h-48 w-48 mx-auto mt-[10%]'>
            <Logo />
          </div>
          <div className='w-10/12 h-[50%] mx-auto text-center'>
            Wir prüfen, ob die Kontoaktivierung erfolgreich war. Bitte warten Sie.
            <div className='font-bold text-xl pt-16 pb-2'>Kontoaktivierungsstatus:</div>
            <div className='font-bold text-3xl'>
              {sellerActive === null && (
                <div className='text-lg'>Warten auf eine Antwort vom Server</div>
              )}
              {sellerActive === true && <div className='text-green-500'>Erfolg</div>}
              {sellerActive === false && <div className='text-red-500'>Fehler</div>}
            </div>
            {sellerActive === false && (
              <>
                <div className='text-red-100 pt-10'>
                  Leider konnten wir Ihr Konto nicht aktivieren. Bitte versuchen Sie es erneut
                </div>
                <div className='flex justify-center pt-10'>
                  <ButtonLink text='Zur App wechseln' routerLink='dashboard'></ButtonLink>
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default VerifySellerAccount;

import React, {useEffect} from 'react';
import {IonContent, IonPage, useIonRouter} from '@ionic/react';
import {useAuth} from '../../contexts/Auth';
import {SignUpData} from '../../interfaces/SignUpData';

import Logo from '../../components/Logo';
import SignInText from '../../components/SignInText';
import useToast from '../../hooks/customToast';
import SignUpForm from '../../forms/SignUp.form';
import {useSetRecoilState} from 'recoil';
import {isBottomTabBarHiddenAtom} from '../../atoms/bottomTabBarAtom';

const Register = () => {
  const {signUp} = useAuth();
  const presentToast = useToast();
  const router = useIonRouter();

  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  const onSubmit = async (signUpData: SignUpData) => {
    const {error} = await signUp(signUpData);

    if (error) {
      await presentToast('E-Mail-Ratenlimit überschritten');
      return;
    }
    router.push('/activate');
    await presentToast('Sie wurden erfolgreich registriert!');
  };

  return (
    <IonPage>
      <IonContent className='flex flex-row items-center justify-center'>
        <div className='w-full h-screen flex flex-col justify-center'>
          <Logo />
          <SignUpForm onSubmit={onSubmit} />
          <SignInText />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Register;

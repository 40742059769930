import {IonButton, IonIcon, useIonRouter} from '@ionic/react';
import React, {FC} from 'react';
import search from '../../static/search.svg';

export const SearchButton: FC = () => {
  const router = useIonRouter();

  const handleGoToSearch = () => {
    router.push('/search');
  };

  return (
    <IonButton onClick={handleGoToSearch}>
      <IonIcon className='text-white text-xl tablet:text-2xl desktop:text-3xl mx-1' icon={search} />
    </IonButton>
  );
};

import React, {FC, useEffect, useMemo, useState} from 'react';
import ShoppingCartElement from './ShoppingCart.element';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {cartActionsCount, getCartProducts} from '../../selectors/cartSelector';
import cartsService from '../../services/cartsService';
import paymentsService from '../../services/paymentsService';
import {useStripe} from '@stripe/react-stripe-js';
import {useAuth} from '../../contexts/Auth';

import _ from 'lodash';
import {ShoppingCartTotalPrice} from './ShoppingCartTotalPrice';
import {supabase} from '../../supabaseClient';
import {getUserAddressInformation} from '../../selectors/userSelectors';
import UserAddressModal from './UserAddressInfoModal';

interface ShoppingCartProductsListProps {}

export const ShoppingCartProductsList: FC<ShoppingCartProductsListProps> = ({}) => {
  const stripe = useStripe();
  const {user} = useAuth();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const cartProducts = useRecoilValue(getCartProducts);
  const userAddressInformation = useRecoilValue(getUserAddressInformation(user!.id));
  const [changeableCartProducts, setChangeableCartProducts] = useState(cartProducts.slice());
  const [realCartProducts, setRealCartProducts] = useState(cartProducts);

  const setCartActionsCount = useSetRecoilState(cartActionsCount);
  const [cartTotalPrice, setCartTotalPrice] = useState<number>(
    useMemo(() => _.sumBy(cartProducts, (x) => x.price), [cartProducts]),
  );

  useEffect(() => {
    setCartActionsCount((prev) => prev + 1);
  }, []);

  const handleDeleteItem = async (productId: number) => {
    await cartsService.removeProductFromCurrentUserCart(productId);
    const filteredReal = realCartProducts.filter((item) => item.id !== productId);
    setRealCartProducts(filteredReal);
    const filteredChangeable = changeableCartProducts.filter((item) => item.id !== productId);
    setChangeableCartProducts(filteredChangeable);
    const currentCartTotalPrice = filteredChangeable.reduce((accumulator, item) => {
      if (!item.ignore) {
        return accumulator + item.price;
      }
      return accumulator;
    }, 0);
    setCartTotalPrice(currentCartTotalPrice);
    setCartActionsCount((prev) => prev + 1);
  };

  const handleIgnoreProduct = async (ignoreValue: boolean, productId: number) => {
    const indexToChange = changeableCartProducts.findIndex((item) => item.id === productId);
    if (indexToChange !== -1) {
      const updatedObject = {...changeableCartProducts[indexToChange], ignore: ignoreValue};

      changeableCartProducts[indexToChange] = updatedObject;
      setChangeableCartProducts(changeableCartProducts);
    }

    const currentCartTotalPrice = changeableCartProducts.reduce((accumulator, item) => {
      if (!item.ignore) {
        return accumulator + item.price;
      }
      return accumulator;
    }, 0);

    setRealCartProducts(
      changeableCartProducts.filter((item) => item.ignore === false || item.ignore === undefined),
    );

    setCartTotalPrice(currentCartTotalPrice);
  };

  const handleBuyClicked = async () => {
    if (userAddressInformation === -1) {
      setModalOpen(true);
      return;
    }
    const realCartIds = realCartProducts.map((x: any) => x.id);
    await supabase.rpc('create_payment_cart', {
      user_id: user!.id,
      integer_array: realCartIds,
    });
    const sessionId = await paymentsService.retrieveCheckoutSessionId(
      user?.email!,
      realCartProducts,
    );
    const stripeResponse = await stripe?.redirectToCheckout({sessionId});
    if (stripeResponse?.error) {
      throw stripeResponse.error.message;
    }
  };

  return (
    <div className={cartProducts.length ? 'pt-2 pb-32' : 'pt-2 pb-6'}>
      {cartProducts.map((el) => (
        <ShoppingCartElement
          key={el.id}
          productId={el.id}
          productName={el.name}
          productDescription={el.description}
          productPrice={el.price}
          productImage={el.firstProductImageUrl}
          ignoreProduct={handleIgnoreProduct}
          deleteSelectedItem={() => handleDeleteItem(el.id)}
        />
      ))}
      {realCartProducts.length > 0 && (
        <ShoppingCartTotalPrice
          itemsInCart={realCartProducts.length}
          totalPrice={cartTotalPrice}
          goToPayment={handleBuyClicked}
        />
      )}
      <UserAddressModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)} />
    </div>
  );
};

import {IonLoading} from '@ionic/react';
import React, {FC, Suspense} from 'react';

type IonLoadingSuspenseProps = {
  children: JSX.Element;
  isOpen?: boolean;
};

const IonLoadingSuspense: FC<IonLoadingSuspenseProps> = ({children, isOpen = true}) => (
  <Suspense fallback={<IonLoading isOpen={isOpen} />}>{children}</Suspense>
);

export default IonLoadingSuspense;

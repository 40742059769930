import React from 'react';
import {Route, Switch} from 'react-router';
import EditProfile from '../../pages/Profiles/EditProfile.page';
import ChangePassword from '../../pages/Profiles/ChangePassword.page';
import Dasboard from '../../pages/Products/Dashboard.page';
import MyProducts from '../../pages/Products/MyProducts/MyProducts.page';
import AddProduct from '../../pages/Products/AddProduct/AddProduct.page';
import DetailProduct from '../../pages/Products/DetailsProduct/DetailsProduct.page';
import {Inbox} from '../../pages/Chat/Inbox.page';
import {Conversation} from '../../pages/Chat/Conversation.page';
import FavoritesProducts from '../../pages/Products/FavoritesProducts/FavoritesProducts.page';
import SearchPage from '../../pages/Search/Search.page';
import ShoppingCart from '../../pages/Cart/ShoppingCart.page';
import HistoryProductsPage from '../../pages/Products/HistoryProducts/HistoryProducts.page';
import AllProducts from '../../pages/Products/AllProducts/AllProducts.page';
import LegalsPage from '../../pages/Commons/Legals.page';
import OnSaleProducts from '../../pages/Products/SaleProducts/SaleProducts.page';
import PaymentFail from '../../pages/PaymentInfo/PaymentFail';
import PaymentSucces from '../../pages/PaymentInfo/PaymentSucces';
import ProfileTab from '../../pages/ProfileTab/ProfileTab.page';
import AllSellerProducts from '../../pages/Products/AllSelerProducts/AllSellerProducts.page';
import SizesPage from '../../pages/Sizes/Sizes.page';
import VerifySellerAccount from '../../pages/Profiles/VerifySellerAccount';
import RegisterConfirmed from '../../pages/Auth/RegisterConfirmed';

const PrivateRoutes = () => (
  <Switch>
    <Route path='/dashboard' component={Dasboard} />
    <Route path='/products' component={AllProducts} />
    <Route path='/myproducts' component={MyProducts} />
    <Route path='/seller-products' component={AllSellerProducts} />

    <Route path='/cart' component={ShoppingCart} />
    <Route path='/search' component={SearchPage} />
    <Route path='/history' component={HistoryProductsPage} />
    <Route path='/favorite-products' component={FavoritesProducts} />
    <Route path='/sale' component={OnSaleProducts} />
    <Route path='/profiletab' component={ProfileTab} />
    <Route path='/sizes' component={SizesPage} />

    <Route path='/add' component={AddProduct} />
    <Route path='/details/:productId' component={DetailProduct} />
    <Route path='/inbox' component={Inbox} />
    <Route path='/conversation/:interlocutorId/:productId' component={Conversation} />

    <Route path='/profile' component={EditProfile} />
    <Route path='/success/:email' component={PaymentSucces} />
    <Route path='/failed' component={PaymentFail} />
    <Route path='/verify' component={VerifySellerAccount} />
    <Route path='/activated' component={RegisterConfirmed} />

    <Route path='/changePassword' component={ChangePassword} />
    <Route path='/legals' component={LegalsPage} />

    <Route component={Dasboard} />
  </Switch>
);

export default PrivateRoutes;

import React, {FC, useEffect} from 'react';
// eslint-disable-next-line camelcase
import {useRecoilRefresher_UNSTABLE, useRecoilValue} from 'recoil';
import {categories, colors, materials, sizes, subCategories} from '../../atoms/selectProductAtom';
import BaseFormProps from '../../interfaces/BaseFormProps';
import {UIFields} from '../../components/UIFields';
import {currentUserProfile} from '../../atoms/currentUserProfileAtom';
import {useAuth} from '../../contexts/Auth';
import CreateConnectedAccountButton from '../../components/CreateConnectedAccountButton';
import {allLoggedUserProductsOnSale} from '../../selectors/productsSelectors';
import {MAX_PRODUCTS_AMOUNT_ON_SALE_AT_ONCE} from '../../utils/constants';

const AddProductForm: FC<BaseFormProps<any>> = ({onSubmit, children, onPreviewClick}) => {
  const {user} = useAuth();
  const subCategoriesData: any = useRecoilValue(subCategories);
  const userProfile = useRecoilValue(currentUserProfile(user!.id));
  const sizesData = useRecoilValue(sizes);
  const materialsData = useRecoilValue(materials);
  const categoriesData = useRecoilValue(categories);
  const colorsData = useRecoilValue(colors);
  const userProducts = useRecoilValue(allLoggedUserProductsOnSale);
  const userProductsRefresher = useRecoilRefresher_UNSTABLE(allLoggedUserProductsOnSale);

  useEffect(() => {
    userProductsRefresher();
  }, []);

  if (!userProfile?.charges_enabled) {
    return (
      <div className='flex items-center justify-center flex-col h-full'>
        <div>Ihr Verkäuferkonto ist noch nicht aktiviert</div>
        <CreateConnectedAccountButton />
      </div>
    );
  }

  if (userProducts && userProducts.length >= MAX_PRODUCTS_AMOUNT_ON_SALE_AT_ONCE) {
    return (
      <div className='flex items-center justify-center flex-col h-full w-full text-center'>
        <div className='flex w-5/6 pb-10 text-center'>
          more.moda möchte exklusive und individuelle Mode anbieten und beschränkt deswegen die
          Menge an gleichzeitig einstellbaren Artikeln pro Verkäufer auf 10 Stück.
          <br />
          Damit möchte wir auch dem Versuch von großen Anbietern vorbeugen, Massenware als
          „Einzelstücke“ über die more.moda- Plattform zu vertreiben.
          <br />
          {/* eslint-disable-next-line */} {/* Eslint nie radzi sobie z niemieckimi znakami */}
          Du kannst uns diesbezüglich aber gerne per Mail kontaktieren, solltest du den dringenden
          Bedarf an eine höheren Anzahl Artikel haben.
        </div>
      </div>
    );
  }

  return (
    <UIFields
      onSubmit={onSubmit}
      subCategoriesData={subCategoriesData}
      sizesData={sizesData}
      materialsData={materialsData}
      categoriesData={categoriesData}
      colorsData={colorsData}
      previewClicked={onPreviewClick}
      addOrEditLabel='Hinzufügen'
    />
  );
};

export default AddProductForm;

import React, {FC} from 'react';
import {IonItem, IonLabel} from '@ionic/react';
import {isDetailModalOpenedAtom} from '../../atoms/filterModalAtom';
import {useSetRecoilState} from 'recoil';
import {choosenFilterAtom} from '../../atoms/choosenFilterAtom';
import {filtersTranslator} from '../../utils/filtersTranslator';

type FilterModalOptionProps = {
  type: 'price' | 'size' | 'color' | 'material';
  value: string;
};

const FilterModalOption: FC<FilterModalOptionProps> = ({type, value}) => {
  const setIsDetailModalOpened = useSetRecoilState(isDetailModalOpenedAtom);
  const setChoosenFilter = useSetRecoilState(choosenFilterAtom);

  const handleItemClick = () => {
    setChoosenFilter(type);
    setIsDetailModalOpened(true);
  };

  return (
    <IonItem
      onClick={handleItemClick}
      button
      lines='none'
      className='my-3 mx-auto w-11/12 tablet:w-4/5 desktop:w-4/5
      bg-offWhite bg-opacity-25 rounded-3xl'
    >
      <IonLabel slot='start' className='capitalize'>
        {filtersTranslator(type)}
      </IonLabel>
      <IonLabel slot='end' className='text-white text-opacity-80'>
        {value}
      </IonLabel>
    </IonItem>
  );
};

export default FilterModalOption;

import {IonButton, useIonRouter} from '@ionic/react';
import React, {FC} from 'react';

type Props = {
  boughtDate: any;
};

export const BoughtGreenBarElement: FC<Props> = ({boughtDate}) => {
  const router = useIonRouter();
  return (
    <div
      className='flex justify-around items-center w-full h-6 bg-primary rounded-br-xl text-xs
      desktop:text-sm font-medium text-white'
    >
      <div>{boughtDate}</div>
      <IonButton
        onClick={() => router.push('/add')}
        className='w-1/2 h-5 rounded-lg desktop:text-sm text-xs'
        color='light'
      >
        Wiederverkauf
      </IonButton>
    </div>
  );
};

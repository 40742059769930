import React from 'react';
import {IonBadge, IonIcon, IonItem} from '@ionic/react';
import styles from './BurgerMenu.module.css';

interface Props {
  routerLink: string;
  label: string;
  icon: string;
  messageBadge?: any;
}

export const BurgerMenuWebItem: React.FC<Props> = ({routerLink, label, icon, messageBadge}) => {
  return (
    <>
      <IonItem routerLink={routerLink}>
        <div>{label}</div>
        <IonIcon className='text-2xl text-white' icon={icon} slot='end' />
        {messageBadge > 0 && (
          <IonBadge mode='ios' className={styles.ionBadge}>
            {messageBadge}
          </IonBadge>
        )}
      </IonItem>
    </>
  );
};

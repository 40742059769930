import React, {FC, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {heart, heartOutline} from 'ionicons/icons';
import productsService from '../../services/productsService';
import {favoritesActionsCount} from '../../selectors/favoritesSelector';
import {useSetRecoilState} from 'recoil';

type Props = {
  productId: number;
  isFavorited: boolean;
  className?: string;
};

export const FavoritesButton: FC<Props> = ({productId, isFavorited, className}) => {
  const [isFavorite, setFavorite] = useState<boolean>(isFavorited);
  const setFavoritesActionsCount = useSetRecoilState(favoritesActionsCount);

  const handleFavoriteClicked = async () => {
    if (!isFavorite) {
      setFavorite(true);
      await productsService.insertProductToFavorites(productId);
    } else {
      setFavorite(false);
      await productsService.removeProductFromFavorites(productId);
    }

    setFavoritesActionsCount((prev) => prev + 1);
  };

  return (
    <IonIcon
      className={className}
      icon={isFavorite ? heart : heartOutline}
      color={isFavorite ? 'danger' : undefined}
      onClick={handleFavoriteClicked}
    />
  );
};

import React, {FC} from 'react';
import {IonRouterLink} from '@ionic/react';

const SignUpText: FC = () => {
  return (
    <div className='w-full text-center text-sm font-light mt-2'>
      Hast Du noch kein Konto?
      <IonRouterLink
        className='mobile:underline tabler:underline destkop:hover-underline ml-1'
        routerLink='/register'
        routerDirection='forward'
      >
        Hier anmelden
      </IonRouterLink>
    </div>
  );
};

export default SignUpText;

import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import GridBox from '../../../components/GridBox';
import {Product} from '../../../interfaces/Product';
import {allProducts, allProductsByCategory} from '../../../selectors/productsSelectors';
import OnSaleProductCard from './SaleProduct.element';
import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';

const OnSaleProductsList: React.FC = () => {
  const allProductsRecoil = useRecoilValue(allProducts);
  const allCategoryProducts = useRecoilValue(allProductsByCategory);
  const chosenSubcategoryId = useRecoilValue(choosenSubcategoryIdAtom);
  const [saleProducts, setSaleProducts] = useState<any>([]);
  const [alleProducts, setAlleProducts] = useState<any>([]);

  const selectedProducts = chosenSubcategoryId === 0 ? alleProducts : saleProducts;

  useEffect(() => {
    setAlleProducts(allCategoryProducts?.filter((x: Product) => x.isOnSale === true));
    setSaleProducts(allProductsRecoil?.filter((x: Product) => x.isOnSale === true));
  }, [allProductsRecoil, allCategoryProducts]);

  return (
    <>
      {saleProducts && (
        <GridBox
          content={selectedProducts.map((onSaleProduct: Product) => (
            <div key={onSaleProduct.productId}>
              <OnSaleProductCard {...onSaleProduct} />
            </div>
          ))}
        />
      )}
    </>
  );
};
export default OnSaleProductsList;

import React from 'react';
import {IonRouterLink} from '@ionic/react';
import {ComponentProps, FC} from 'react';
import TextInput from './TextInput/TextInput';

type PasswordInputProps = ComponentProps<typeof TextInput>;

const PasswordInput: FC<PasswordInputProps> = (props) => {
  return (
    <>
      <TextInput {...props} type='password' />
      <IonRouterLink
        routerLink='/recover'
        routerDirection='forward'
        className='flex justify-center relative z-10 left-24
        tablet:left-20 desktop:left-32 bottom-1.5'
      >
        <span
          className='mobile:underline tabler:underline destkop:hover-underline text-light
          text-xs tablet:text-sm desktop:text-base'
        >
          Passwort vergessen?
        </span>
      </IonRouterLink>
    </>
  );
};

export default PasswordInput;

import {atom, selector} from 'recoil';
import {choosenSubcategoryIdAtom} from '../atoms/choosenSubCategoryIdAtom';
import {FavoriteProduct} from '../interfaces/FavoriteProduct';
import {supabase} from '../supabaseClient';
import {BASE_STORAGE_URL} from '../utils/constants';

export const favoritesActionsCount = atom({key: 'favoritesActionsCount', default: 0});

export const favoritesSelector = selector<FavoriteProduct[]>({
  key: 'favoritesSelector',
  get: async ({get}) => {
    get(favoritesActionsCount);
    const choosenSubcategoryId = get(choosenSubcategoryIdAtom);
    if (choosenSubcategoryId == null) {
      return [];
    }
    const {data, error} = await supabase
      .rpc('get_favorites_current_user_products')
      .select(
        `id,
        name,
        subcategory,
        owner_name,
        price,
        old_price,
        description,
        size,
        is_sold,
        is_on_sale,
        is_in_cart,
        is_it_my_product,
        images:products_images(image_url)`,
      )
      .eq('subcategory', choosenSubcategoryId)
      .limit(1, {foreignTable: 'images'});

    if (error) {
      throw new Error(error.message);
    }

    return data.map((x) => {
      const firstImageUrl = x.images?.[0]?.image_url
        ? `${BASE_STORAGE_URL}/products/${x.images[0].image_url}`
        : '';

      return {
        productId: x.id,
        productName: x.name,
        ownerName: x.owner_name,
        price: x.price,
        oldPrice: x.old_price,
        description: x.description,
        size: x.size,
        isSold: x.is_sold,
        isOnSale: x.is_on_sale,
        isInCart: x.is_in_cart,
        isItMyProduct: x.is_it_my_product,
        firstImageUrl: firstImageUrl,
      } as FavoriteProduct;
    });
  },
});

export const getUserFavoritesProductsSelector = selector<FavoriteProduct[]>({
  key: 'getUserFavoritesProductsSelector',
  get: async ({get}) => {
    get(favoritesActionsCount);
    const {data, error} = await supabase.rpc('get_favorites_current_user_products').select(
      `id,
          name,
          subcategory,
          owner_name,
          price,
          old_price,
          description,
          size,
          is_sold,
          is_on_sale,
          is_in_cart,
          is_it_my_product,
          images:products_images(image_url)`,
    );

    if (error) {
      throw new Error(error.message);
    }

    return data.map((x) => {
      const firstImageUrl = x.images?.[0]?.image_url
        ? `${BASE_STORAGE_URL}/products/${x.images[0].image_url}`
        : '';

      return {
        productId: x.id,
        productName: x.name,
        ownerName: x.owner_name,
        price: x.price,
        oldPrice: x.old_price,
        description: x.description,
        size: x.size,
        isSold: x.is_sold,
        isOnSale: x.is_on_sale,
        isInCart: x.is_in_cart,
        isItMyProduct: x.is_it_my_product,
        firstImageUrl: firstImageUrl,
      } as FavoriteProduct;
    });
  },
});

import {IonLoading, IonPage} from '@ionic/react';
import React, {Suspense} from 'react';
import DetailsProductInfo from './DetailsProductInfo.page';

const DetailProduct = () => {
  return (
    <IonPage>
      <Suspense fallback={<IonLoading isOpen={true} />}>
        <DetailsProductInfo />
      </Suspense>
    </IonPage>
  );
};
export default DetailProduct;

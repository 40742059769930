import React from 'react';
import {IonContent, IonHeader, IonList, IonToolbar} from '@ionic/react';
import styles from './BurgerMenu.module.css';
import Logo from '../Logo';
import {LogOutButton} from '../Buttons/LogOutButton';
import {VersionText} from '../VersionText';
import {BurgerMenuMobileItem} from './BurgerMenuMobile.item';
import {useAuth} from '../../contexts/Auth';
import useToast from '../../hooks/customToast';

export const BurgerMenuMobile: React.FC = () => {
  const auth = useAuth();
  const presentToast = useToast();

  const signOutHandler = () => {
    presentToast('Du hast dich erfolgreich abgemeldet');
    auth.signOut();
  };

  return (
    <>
      <IonHeader className={styles.ionHeader}>
        <IonToolbar className={styles.ionToolbar}>
          <Logo iconWidth={60} textWidth={150} />
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.ionContent}>
        <div className={styles.contentBackground} />
        <IonList className='bg-transparent'>
          <BurgerMenuMobileItem routerLink='/profile' label='Mein Profil' />
          <BurgerMenuMobileItem
            routerLink='/favorite-products'
            label='Meine bevorzugten Produkte'
          />
          <BurgerMenuMobileItem routerLink='/myproducts' label='Meine Produkte' />
          <BurgerMenuMobileItem routerLink='/history' label='Bereits gekauft/verkauft' />
          <BurgerMenuMobileItem routerLink='/legals' label='Rechtsinformation' />
          <BurgerMenuMobileItem routerLink='/sizes' label='Größen' />
        </IonList>
        <div className='absolute bottom-0 w-full'>
          <VersionText />
          {auth.user !== null ? (
            <div onClick={signOutHandler}>
              <LogOutButton />
            </div>
          ) : (
            <div className='pt-[69px]'></div>
          )}
        </div>
      </IonContent>
    </>
  );
};

import React, {useEffect, useState} from 'react';
import {IonContent, IonIcon, IonImg, IonRouterLink} from '@ionic/react';
import {chevronForwardOutline} from 'ionicons/icons';
import {useRecoilValue} from 'recoil';
import {currentUserProfile} from '../../../atoms/currentUserProfileAtom';
import {
  PreviewObject,
  previewProductDataAtom,
  ProductPreview,
} from '../../../atoms/previewProductDataAtom';
import {SliderImages} from '../../../components/Slider/Slider';
import {useAuth} from '../../../contexts/Auth';

const DetailsProductInfoPreview = () => {
  const {user} = useAuth();
  const ownerInfo = useRecoilValue(currentUserProfile(user!.id));
  const productDetailsInfo: ProductPreview = useRecoilValue(previewProductDataAtom);
  const [imagesBlobs, setImagesBlobs] = useState<any>();

  const tempArray: any[] = [];

  useEffect(() => {
    sortData();
    setImagesBlobs(tempArray);
  }, []);

  const sortData = () => {
    if (productDetailsInfo.imageUrl) {
      for (let i = 0; i < productDetailsInfo.imageUrl.length; i++) {
        tempArray.push([
          {data: productDetailsInfo.imageUrl[i].file},
          productDetailsInfo.imageUrl[i].filePath,
        ]);
      }
    }
  };

  return (
    <>
      <IonContent className='h-[85%]'>
        <div>
          <div
            className='flex items-center h-24 w-full tablet:w-[75%] desktop:w-[60%]
                    mx-auto bg-white'
          >
            <img
              className='h-24 w-24 bg-white '
              src={ownerInfo?.avatar && URL.createObjectURL(ownerInfo.avatar?.file)}
              alt={'avatar'}
            />
            <div
              className='bg-white text-darkGray text-sm w-screen flex flex-col
      justify-center space-y-1 pl-3'
            >
              <p>
                Name: {ownerInfo.first_name} {ownerInfo.last_name}
              </p>
            </div>
            <div className='flex justify-center items-center bg-primary w-[10%] h-24 text-2xl'>
              <IonRouterLink routerLink='/seller-products'>
                <IonIcon className='text-white' icon={chevronForwardOutline} />
              </IonRouterLink>
            </div>
          </div>
          <div
            className='mx-auto tablet:w-[75%] desktop:w-[60%]
                    border-b-2 border-gray-400'
          ></div>
          <div
            className='w-full desktop:h-full mx-auto mobile:h-[90%]
    tablet:w-[75%] desktop:w-[60%] bg-darkGreen bg-opacity-20'
          >
            {imagesBlobs && imagesBlobs[0] ? (
              <SliderImages imagesBlobs={imagesBlobs} />
            ) : (
              <IonImg
                className='bg-center bg-contain bg-no-repeat h-96 w-full'
                src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
              ></IonImg>
            )}
            <div
              className='bg-darkGray px-3 h-10 flex items-center justify-around -mb-0.5
      mobile:justify-around text-sm'
            >
              <div className='flex'>
                <div className='font-thin'>Größe: </div>
                <div className='pl-1'>
                  {productDetailsInfo.sizes.map((size: PreviewObject) => size.name).join(', ')}
                </div>
              </div>
              <div className='flex'>
                <div className='font-thin'>Preis:</div>
                <div className='pl-1'>{productDetailsInfo.price}€</div>
              </div>
              <div>{productDetailsInfo.subcategory.name}</div>
            </div>
            <div className='px-12 pt-6 font-semibold'>
              Beschreibung:
              <div className='font-normal pt-2'>{productDetailsInfo.description}</div>
            </div>
          </div>
        </div>
      </IonContent>
    </>
  );
};
export default DetailsProductInfoPreview;

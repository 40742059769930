import React, {FC} from 'react';
import regularExpressions from '../../utils/regularExpressions';
import BIonForm from '../../components/BIonForm';
import TextInput from '../../components/TextInput/TextInput';
import BaseFormProps from '../../interfaces/BaseFormProps';
import {FormField} from '../../interfaces/FormField';
import ChangePasswordData from '../../interfaces/ChangePasswordData';

const ChangePasswordForm: FC<BaseFormProps<ChangePasswordData>> = ({onSubmit}) => {
  const fields: FormField[] = [
    {
      name: 'password',
      component: <TextInput label='Neues Passwort' type='password' />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
        pattern: {
          message: `Mindestens 8 Zeichen, mindestens ein Groß- und Kleinbuchstabe
          Kleinbuchstaben, eine Zahl und ein Sonderzeichen.`,
          value: regularExpressions.password,
        },
      },
    },
  ];

  return <BIonForm fields={fields} submitBtnText='Speichern'
  customCss='pt-8' onSubmit={onSubmit} />;
};

export default ChangePasswordForm;

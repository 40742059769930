import React, {FC} from 'react';
import {MainProductElement} from '../ProductCardComponents/MainProduct.element';

type Props = {
  productName: string;
  price: string;
  sizes: string[];
  description: string;
  firstImageUrl: string;
  sellerName: [];
  boughtDate: string;
};

const BoughtProductElement: FC<Props> = ({
  productName,
  price,
  sizes,
  description,
  firstImageUrl,
  sellerName: sellerName,
  boughtDate,
}) => {
  return (
    <MainProductElement
      image={firstImageUrl}
      productName={productName}
      description={description}
      sizes={sizes}
      price={price}
      sellerName={sellerName}
      boughtDate={boughtDate}
      soldBar={false}
      boughtBar={true}
    />
  );
};

export default BoughtProductElement;

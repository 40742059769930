import React, {FC} from 'react';
import {IonCard, IonText, useIonRouter} from '@ionic/react';
import {FavoritesButton} from './Buttons/FavouriteButton';
import {ShoppingCartButton} from '../pages/Cart/ShoppingCart.button';
import {Product} from '../interfaces/Product';
import {useRecoilValue} from 'recoil';
import {getCartProducts} from '../selectors/cartSelector';
import {useAuth} from '../contexts/Auth';
import {getTmpCartSelector} from '../atoms/tmpCartSelector';

type ProductCardProps = Product;

const ProductCard: FC<ProductCardProps> = ({
  productId,
  productName,
  price,
  sizes,
  isSold,
  firstImageUrl,
  isItMyProduct,
  isFavorited,
}) => {
  const {user} = useAuth();
  const router = useIonRouter();
  const cartProducts = useRecoilValue(getCartProducts);
  const tmpCartProducts = useRecoilValue(getTmpCartSelector);

  return (
    <IonCard
      className='h-48 desktop:h-64 flex flex-col items-stretch m-1.5 bg-offWhite rounded-2xl
      cursor-pointer'
    >
      {!isItMyProduct && !isSold && (
        <ShoppingCartButton
          productId={productId}
          isInCart={cartProducts.some((x) => x.id === productId)}
          isUserAuth={user != null}
          isInNonLoggedUserCart={tmpCartProducts.some((x: any) => x == productId)}
          className='absolute left-1 top-1 text-xl tablet:text-2xl desktop:text-3xl z-10'
        />
      )}
      {user && !isItMyProduct && (
        <FavoritesButton
          productId={productId}
          isFavorited={isFavorited}
          className='absolute right-1 top-1 text-2xl tablet:text-3xl desktop:text-4xl
        text-primary z-10'
        />
      )}
      <div
        className='h-5/6 relative flex justify-center items-center'
        onClick={() => !isSold && router.push(`/details/${productId}`)}
      >
        {firstImageUrl ? (
          <div
            className='bg-center bg-cover bg-no-repeat h-full w-full '
            style={{
              backgroundImage: `url(${
                'https://fmnftbssqvgsvwykyfzh.supabase.co/storage/v1/object/public/products/' +
                firstImageUrl
              })`,
            }}
          />
        ) : (
          <div
            className='bg-center bg-cover bg-no-repeat h-full w-full -m-1'
            style={{
              backgroundImage: `url('https://ionicframework.com/docs/img/demos/thumbnail.svg')`,
            }}
          />
        )}
        <div className='absolute bottom-0 w-full text-center'>
          {isSold && (
            <div className='bg-pureOrange'>
              <IonText className='text-white font-light'>VERKAUFT</IonText>
            </div>
          )}
          <div className={`${isSold ? 'bg-gray-400' : 'bg-gray-900/50'}`}>
            <IonText className='text-white font-light line-clamp-1'>{productName}</IonText>
          </div>
        </div>
      </div>
      <div className='h-1/6 bg-primary flex flex-row items-center justify-around px-1'>
        <IonText className='flex text-white font-light truncate'>
          Größe:&nbsp;
          <span className='font-bold truncate'>
            {sizes && sizes.map((size) => size).join(', ')}
          </span>
        </IonText>
        <IonText className='text-white font-light'>
          Preis:&nbsp;<span className='font-normal'>{price}€</span>
        </IonText>
      </div>
      {isSold && <div className='absolute w-full h-full bg-white/40 bottom-0' />}
    </IonCard>
  );
};

export default ProductCard;

import {atom, selector} from 'recoil';
import {useAuth} from '../contexts/Auth';
import {BoughtProduct} from '../interfaces/BoughtProduct';
import {SoldProduct} from '../interfaces/SoldProduct';
import {supabase} from '../supabaseClient';

export const historyActionsCount = atom({key: 'historyActionsCount', default: 0});

export const getSoldProductsSelector = selector({
  key: 'getCurrentUserSoldProducts',
  get: async ({get}) => {
    get(historyActionsCount);

    const {user} = useAuth();
    const {data, error} = await supabase
      .rpc('get_all_products2')
      .select('id, name, price, owner_id, description, sizes, is_sold, custom_text_1')
      .eq('owner_id', user!.id)
      .eq('is_sold', true);

    if (error) {
      throw error.message;
    }

    return data.map((x) => {
      return {
        productId: x.id,
        productName: x.name,
        price: x.price,
        sizes: x.sizes,
        description: x.description,
        firstImageUrl: x.custom_text_1,
        ownerName: user?.user_metadata.nick_name,
        boughtDate: get(getProductSaleDate),
      } as SoldProduct;
    });
  },
});

export const getBoughtProductsSelector = selector({
  key: 'getCurrentUserBoughtProducts',
  get: async ({get}) => {
    get(historyActionsCount);

    const {user} = useAuth();
    const {data, error} = await supabase
      .from('carts')
      .select('bought_at, bought_history')
      .eq('status', 'BOUGHT')
      .eq('owner_id', user!.id);

    if (error) {
      throw error.message;
    }

    const dataToReturn = data.map((cart: any) => {
      return cart.bought_history.map((product: any) => {
        return {
          productId: product.id,
          productName: product.name,
          price: product.price,
          sizes: product.sizes,
          description: product.description,
          firstImageUrl: product.custom_text_1,
          boughtDate: cart.bought_at.slice(0, 10),
          sellerName: product.seller_name,
        } as BoughtProduct;
      });
    });
    return dataToReturn;
  },
});

export const getProductSaleDate = selector({
  key: 'getProductSaleDate',
  get: async ({get}) => {
    get(historyActionsCount);

    const {data, error} = await supabase
      .from('carts')
      .select('bought_at, bought_history')
      .eq('status', 'BOUGHT');

    if (error) {
      throw error.message;
    }

    return data;
  },
});

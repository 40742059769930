import React from 'react';
import {IonContent, IonHeader, IonPage, useIonAlert} from '@ionic/react';
import {supabase} from '../../supabaseClient';
import {useAuth} from '../../contexts/Auth';
import Button from '../../components/Buttons/Button';
import useToast from '../../hooks/customToast';
import {UserProfile} from '../../interfaces/UserProfile';
import ButtonLink from '../../components/Buttons/ButtonLink';
import EditProfileForm from '../../forms/EditProfile/EditProfile.form';
import {useRecoilRefresher_UNSTABLE as useRecoilRefresher} from 'recoil';
import {currentUserProfile} from '../../atoms/currentUserProfileAtom';
import IonLoadingSuspense from '../../components/IonLoadingSuspense';
import usersService from '../../services/usersService';
import './ProfileAlert.css';
import AppToolbar from '../../components/AppToolbar';
import {BackButton} from '../../components/Buttons/BackButton';
import {getUserAddressInformation} from '../../selectors/userSelectors';

const EditProfile = () => {
  const {user, signOut} = useAuth();
  const presentToast = useToast();
  const [presentAlert] = useIonAlert();

  const refreshUserProfile = useRecoilRefresher(currentUserProfile(user!.id));
  const refreshUserAddress = useRecoilRefresher(getUserAddressInformation(user!.id));
  const handleProfileUpdate = async (userProfile: UserProfile) => {
    const {avatar} = userProfile;

    if (avatar && avatar.filePath && avatar.file) {
      const {error: avatarError} = await supabase.storage
        .from('avatars')
        .upload(avatar.filePath, avatar.file);

      if (avatarError) {
        await presentToast(avatarError.message, 'error');
        return;
      }
    }

    const dataToUpdate = {
      first_name: userProfile.first_name,
      last_name: userProfile.last_name,
      avatar_url: avatar.filePath,
      street_name: userProfile.street_name,
      house_number: userProfile.house_number,
      postal_code: userProfile.postal_code,
      city: userProfile.city,
      country: userProfile.country,
      phone_number: userProfile.phone_number,
    };

    const {error: profileError} = await supabase
      .from('profiles')
      .update(dataToUpdate)
      .eq('user_id', user!.id);

    if (profileError) {
      await presentToast(profileError.message, 'error');
      return;
    }

    await presentToast('Profil erfolgreich bearbeitet!');
    refreshUserProfile();
    refreshUserAddress();
  };

  const handleSignOut = async () => {
    const {error} = await signOut();

    if (error) {
      await presentToast(error.message, 'error');
    }
  };

  const deleteAccount = () => {
    usersService.deleteUserProfile(user!.id);

    handleSignOut();
  };

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar leftSlot1={<BackButton />} titleText='Profil' />
      </IonHeader>
      <IonContent scrollY={true}>
        <IonLoadingSuspense>
          <>
            <EditProfileForm onSubmit={handleProfileUpdate} />
            <div className='flex flex-col items-center justify-center'>
              <div className='w-full flex flex-col items-center space-y-5 -mt-5'>
                <ButtonLink
                  routerLink='/changePassword'
                  routerDirection='forward'
                  text='Passwort ändern'
                />
                <Button text={'Abmelden'} onClick={handleSignOut} />
              </div>
              <div className='pt-6'></div>
              <Button
                className='w-[50%] tablet:w-[35%] desktop:w-[27%] capitalize h-12 bg-[#eb445a]
                rounded-xl shadow-md'
                text='Konto löschen'
                onClick={() =>
                  presentAlert({
                    header: 'WARNUNG',
                    subHeader: 'Diese Aktion kann nicht rückgängig gemacht werden',
                    message:
                      // eslint-disable-next-line max-len
                      'Wenn Sie Ihr Profil löschen, werden alle Ihre Informationen aus unserer Datenbank entfernt. Möchten Sie fortfahren?',
                    cssClass: 'account-delete',
                    mode: 'ios',
                    buttons: [
                      {
                        text: 'Abbrechen',
                        cssClass: 'alert-button-cancel',
                      },
                      {
                        handler: () => deleteAccount(),
                        text: 'Ja',
                        cssClass: 'alert-button-confirm',
                      },
                    ],
                  })
                }
              />
            </div>
          </>
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;

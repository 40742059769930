import React, {FC} from 'react';
import {IonTabBar, IonTabButton} from '@ionic/react';
import './HistoryProducts.css';

type Props = {
  sold: string;
  bought: string;
  activeTab: any;
  setActiveTab: (e: any) => void;
};

const HistoryProductsTabs: FC<Props> = ({sold, bought, activeTab, setActiveTab}) => {
  return (
    <IonTabBar
      className='w-1/2 h-7 tablet:h-10 desktop:h-12 space-x-4 bg-transparent'
      onIonTabsWillChange={(e) => setActiveTab(e.detail.tab)}
    >
      <IonTabButton
        tab={sold}
        className={
          activeTab === sold
            ? 'bg-lightGreen rounded-xl text-base'
            : 'bg-darkGreen rounded-xl text-lightGreen text-sm'
        }
      >
        Verkauft
      </IonTabButton>

      <IonTabButton
        tab={bought}
        className={
          activeTab === bought
            ? 'bg-lightGreen rounded-xl text-base'
            : 'bg-darkGreen rounded-xl text-lightGreen text-sm'
        }
      >
        Gekauft
      </IonTabButton>
    </IonTabBar>
  );
};

export default HistoryProductsTabs;

import {atom, selector} from 'recoil';
import {supabase} from '../supabaseClient';
import {currentCategoryId} from './categoryValueAtom';

export const selectId = atom<string | null>({
  key: 'select',
  default: null,
});

export const sizes = selector({
  key: 'size',
  get: async () => {
    const sizes = await supabase.from('sizes').select('name, id');

    return sizes;
  },
});

export const materials = selector({
  key: 'materials',
  get: async () => {
    const materials = await supabase.from('materials').select('name, id');
    return materials;
  },
});

export const categories = selector({
  key: 'categories',
  get: async () => {
    const categories = await supabase.from('categories').select('name, id');
    return categories;
  },
});

export const subCategories = selector({
  key: 'subcategories',
  get: async ({get}) => {
    const choosenSelectCategoryId = get(currentCategoryId);
    const {data, error} = await supabase
      .from('subcategories')
      .select('name, id')
      .eq('category', choosenSelectCategoryId || 0);

    if (error != null) {
      throw error.message;
    }

    if (data == null) {
      return;
    }

    return data;
  },
});

export const colors = selector({
  key: 'colors',
  get: async () => {
    const colors = await supabase.from('colors').select('name, id');
    return colors;
  },
});

import React, {FC, useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {Product} from '../../interfaces/Product';
import {productsActionsCount} from '../../selectors/productsSelectors';
import GridBox from '../../components/GridBox';
import ProductCard from '../../components/ProductCard';
import OnSaleProductCard from './SaleProducts/SaleProduct.element';

type ProductsListProps = {
  products: Product[];
};

const ProductsList: FC<ProductsListProps> = ({products}) => {
  const setProductsActionsCount = useSetRecoilState(productsActionsCount);

  useEffect(() => {
    setProductsActionsCount((prev) => prev + 1);
  }, []);

  return (
    <GridBox
      content={products?.map((x) => {
        if (x.isOnSale) {
          return <OnSaleProductCard {...x} key={x.productId} />;
        } else {
          return <ProductCard {...x} key={x.productId} />;
        }
      })}
    />
  );
};

export default ProductsList;

import React, {FC} from 'react';
import {BoughtGreenBarElement} from './BoughtGreenBar.element';
import {SoldGreenBarElement} from './SoldGreenBar.element';

type Props = {
  image: string;
  productName: string;
  description: string;
  sizes: string[];
  price: string;
  sellerName: any;
  boughtDate: any;
  soldBar: boolean;
  boughtBar: boolean;
};

export const MainProductElement: FC<Props> = ({
  image,
  productName,
  description,
  sizes,
  price,
  sellerName: sellerName,
  boughtDate,
  soldBar,
  boughtBar,
}) => (
  <div
    className='my-3 relative z-10 px-3 desktop:px-0 desktop:w-5/12 desktop:mt-5 inset-x-0
    mx-auto'
  >
    <div className='flex rounded-xl bg-lightGray'>
      <div className='w-1/3 rounded-l-xl flex justify-center m-auto'>
        {image ? (
          <div
            className='break-all bg-contain h-36 w-36 bg-no-repeat'
            style={{
              backgroundImage: `url(${
                'https://fmnftbssqvgsvwykyfzh.supabase.co/storage/v1/object/public/products/' +
                image
              })`,
              backgroundPosition: 'center',
            }}
          />
        ) : (
          <div
            className='bg-center bg-cover bg-no-repeat h-full w-full -m-1'
            style={{
              backgroundImage: `url('https://ionicframework.com/docs/img/demos/thumbnail.svg')`,
            }}
          />
        )}
      </div>
      <div className='flex flex-col w-2/3 bg-white rounded-r-xl text-black'>
        <div className='flex-col ml-3 mt-2'>
          <div className='flex flex-row place-content-between'>
            <div className='font-medium truncate desktop:text-lg'>{productName}</div>
          </div>
          <div className='flex font-medium desktop:text-lg'>
            Verkäufer:&nbsp;
            <div className='font-normal text-lightGreen truncate'>{sellerName}</div>
          </div>
          <div className='text-sm font-medium desktop:text-lg'>
            Beschreibung:
            <p
              className='h-16 text-xs font-light text-gray-500 line-clamp-1 pt-1
              desktop:text-base'
            >
              {description}
            </p>
          </div>
          <div className='flex justify-start space-x-8 text-sm font-light desktop:text-base'>
            <div className='flex'>
              Größe:&nbsp;
              <div className='font-medium'>
                {sizes && sizes.map((size: string) => size).join(', ')}
              </div>
            </div>
            <div className='flex'>
              Preis:&nbsp;<div className='font-medium'>{price}€</div>
            </div>
          </div>
        </div>
        {soldBar && <SoldGreenBarElement boughtDate={boughtDate} />}
        {boughtBar && <BoughtGreenBarElement boughtDate={boughtDate} />}
      </div>
    </div>
  </div>
);

import React, {FC} from 'react';
import {IonRouterLink} from '@ionic/react';

const SignInText: FC = () => {
  return (
    <div className='w-full text-center text-sm font-light mt-2'>
      Hast du bereits ein Konto?
      <IonRouterLink
        className='mobile:underline tabler:underline destkop:hover-underline ml-1'
        routerLink='/login'
        routerDirection='forward'
      >
        Hier anmelden
      </IonRouterLink>
    </div>
  );
};

export default SignInText;

import React, {FC} from 'react';
import {IonItem, IonLabel, IonToggle} from '@ionic/react';
import {useRecoilState} from 'recoil';
import {filterDataAtom} from '../../atoms/filterDataAtom';

const FilterResaleOption: FC = () => {
  const [selectedFilters, setSelectedFilters] = useRecoilState(filterDataAtom);

  const handleToggleChange = (value: 'on' | 'off') => {
    setSelectedFilters((prev) => ({...prev, resale: value === 'off'}));
  };

  return (
    <IonItem
      detail={false}
      lines='none'
      className='my-3 mx-auto w-11/12 tablet:w-4/5 desktop:w-4/5
      bg-offWhite bg-opacity-25 rounded-3xl'
    >
      <IonLabel slot='start' className='capitalize'>
        Wiederverkauf
      </IonLabel>
      <IonToggle
        slot='end'
        checked={selectedFilters.resale}
        value={selectedFilters.resale ? 'on' : 'off'}
        onIonChange={(e) => handleToggleChange(e.target.value as 'on' | 'off')}
      />
    </IonItem>
  );
};

export default FilterResaleOption;

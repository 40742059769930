import React, {FC, useEffect, useState} from 'react';
import {IonCheckbox} from '@ionic/react';
import {FavoritesButton} from '../../../components/Buttons/FavouriteButton';
import {FavoriteProduct} from '../../../interfaces/FavoriteProduct';

type Props = FavoriteProduct & {
  isChecked: boolean;
  onCheckChanged: (productId: number, checked: boolean) => void;
};

const FavoritesProductsElement: FC<Props> = ({
  productId,
  productName,
  firstImageUrl,
  description,
  price,
  oldPrice,
  size,
  isSold,
  isOnSale,
  isInCart,
  isItMyProduct,
  isChecked,
  onCheckChanged,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const discount = 100 - (price / oldPrice) * 100;

  const handleCheck = (e: CustomEvent) => {
    const checked = e.detail.checked;
    onCheckChanged(productId, checked);
  };

  useEffect(() => {
    setIsDisabled(isSold || isItMyProduct || isInCart);
  }, [isSold, isItMyProduct, isInCart]);

  const handleProductClick = (e: React.MouseEvent) => {
    if (e.target instanceof Element && e.target.closest('.FavoritesButton')) {
      return;
    }

    if (!isDisabled) {
      window.location.href = `/details/${productId}`;
    }
  };

  const handleCheckboxClick = (e: React.MouseEvent<HTMLIonCheckboxElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div
      className='my-3 relative z-0 px-3 desktop:px-0 desktop:w-5/12 desktop:mt-5 inset-x-0 mx-auto'
      onClick={handleProductClick}
    >
      <div className='flex rounded-2xl bg-lightGray'>
        <div className='relative w-1/3 flex flex-col justify-center items-center'>
          {firstImageUrl ? (
            <div
              className='bg-center bg-cover bg-no-repeat h-full w-full rounded-l-2xl'
              style={{backgroundImage: `url(${firstImageUrl})`}}
            />
          ) : (
            <div
              className='bg-center bg-cover bg-no-repeat h-full w-full rounded-l-2xl'
              style={{
                backgroundImage: `url('https://ionicframework.com/docs/img/demos/thumbnail.svg')`,
              }}
            />
          )}
          {isSold && (
            <div
              className='w-full pt-0.5 absolute bottom-0 rounded-bl-2xl bg-pureOrange
            flex justify-center items-center text-white desktop:pt-1 z-10'
            >
              VERKAUFT
            </div>
          )}
          {isOnSale && (
            <div
              className='w-full pt-0.5 absolute bottom-0 rounded-bl-2xl bg-pureRed flex
            justify-center items-center text-white desktop:pt-1 z-0 desktop:text-xs
            text-tiny px-3 py-0.5'
            >
              Im Abverkauf! -{discount.toFixed(0)}%
            </div>
          )}
        </div>
        <div
          className={`relative flex flex-col w-2/3 bg-white rounded-r-2xl text-black
        ${isSold ? `opacity-40` : ''}`}
        >
          <div className='flex-col ml-3 mt-2'>
            <div className='flex flex-row place-content-between'>
              <div className='font-medium truncate desktop:text-lg w-[85%]'>{productName}</div>
              <FavoritesButton
                isFavorited={true}
                productId={productId}
                className='absolute z-20 right-6 text-primary text-2xl
                tablet:text-3xl desktop:text-4xl cursor-pointer FavoritesButton'
              />
            </div>
            <div className='text-sm font-medium desktop:text-lg'>
              Beschreibung:
              <p
                className='h-16 text-xs font-light text-gray-500
              line-clamp-1 pt-1 desktop:text-base'
              >
                {description}
              </p>
            </div>
            <div className='flex justify-start space-x-8 text-sm font-light desktop:text-base'>
              <div className='flex'>
                Größe:&nbsp;<div className='font-medium'>{size}</div>
              </div>
              <div className='flex'>
                Preis:&nbsp;<div className='font-medium'>{price}€</div>
              </div>
            </div>
          </div>
          <div
            className='absolute bottom-0 flex items-center w-full bg-primary
          rounded-br-xl text-xs desktop:text-sm text-white space-x-2 py-1 pl-4'
          >
            {isItMyProduct || isSold || isInCart ? (
              <>
                <IonCheckbox disabled checked={isChecked} className='cursor-not-allowed' />
                <div>Kann diesen Artikel nicht kaufen</div>
              </>
            ) : (
              <>
                <IonCheckbox
                  checked={isChecked}
                  onIonChange={handleCheck}
                  className='desktop:cursor-pointer'
                  onClick={handleCheckboxClick}
                />
                <div>Auswählen, um diesen Artikel zu kaufen</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesProductsElement;

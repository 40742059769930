import React, {FC} from 'react';
import {IonButton, useIonRouter} from '@ionic/react';
import cartsService from '../../../services/cartsService';

type Props = {
  priceSum: number;
  selectedProductsIds: number[];
};

export const FavoritesProductPopUp: FC<Props> = ({priceSum, selectedProductsIds}) => {
  const router = useIonRouter();

  const handleGoToCart = async () => {
    await cartsService.insertProductsToCurrentUserCart(selectedProductsIds);
    router.push('/cart');
  };

  const isMoreThanOneProduct = selectedProductsIds.length > 1;

  return (
    <div
      className='fixed bottom-0 z-10 w-full desktop:w-2/5 inset-x-0 mx-auto bg-
      desktop:border-t-2 desktop:border-x-2 desktop:rounded-t-2xl border-offWhite'
    >
      <div className='flex flex-col py-6 space-y-4 bg-darkGreen desktop:rounded-t-2xl'>
        <div className='flex justify-around'>
          <div>
            {isMoreThanOneProduct
              ? `Ausgewählte ${selectedProductsIds.length} Artikel`
              : `Ausgewählte ${selectedProductsIds.length} Artikel`}
          </div>
          <div>Insgesamt: {priceSum}$</div>
        </div>
        <div className='flex justify-center'>
          <IonButton className='w-3/4 font-normal text-white' onClick={handleGoToCart}>
          Ausgewählte kaufen
          </IonButton>
        </div>
      </div>
    </div>
  );
};

import React, {FC, useEffect, useState} from 'react';
import {MainProductElement} from '../ProductCardComponents/MainProduct.element';

type Props = {
  productId: number;
  productName: string;
  price: string;
  sizes: string[];
  description: string;
  firstImageUrl: string;
  ownerName: string;
  boughtDate: [];
};

const SoldProductElement: FC<Props> = ({
  productId,
  productName,
  price,
  sizes,
  description,
  firstImageUrl,
  ownerName,
  boughtDate,
}) => {
  const [date, setDate] = useState<string>();

  useEffect(() => {
    boughtDate.map((x: any) =>
      x.bought_history.find((y: any) => y.id === productId, setDate(x.bought_at.slice(0, 10))),
    );
    // To co sie tutaj dzieje to zbrodnia przeciw ludzkosci, ale dziala.
  }, []);

  return (
    <MainProductElement
      image={firstImageUrl}
      productName={productName}
      description={description}
      sizes={sizes}
      price={price}
      sellerName={ownerName}
      boughtDate={date}
      soldBar={true}
      boughtBar={false}
    />
  );
};

export default SoldProductElement;

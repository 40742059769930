import {IonButton, IonIcon} from '@ionic/react';
import React from 'react';
import {useSetRecoilState} from 'recoil';
import {isFirstModalOpenedAtom} from '../../atoms/filterModalAtom';
import filter from '../../static/filter.svg';

type Props = {};

const FilterButton: React.FC<Props> = ({}) => {
  const setIsFirstModalOpened = useSetRecoilState(isFirstModalOpenedAtom);

  return (
    <IonButton onClick={() => setIsFirstModalOpened(true)}>
      <IonIcon className='text-white text-xl tablet:text-3xl desktop:text-4xl ml-4' icon={filter} />
    </IonButton>
  );
};

export default FilterButton;

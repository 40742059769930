import {IonItem, IonList, IonThumbnail, useIonRouter} from '@ionic/react';
import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {productFirstProductPhotoSelector} from '../../selectors/productsSelectors';
import '../../tailwindcss.css';

type Props = {
  productId: string;
  ownerId: string;
  name: string;
  owner: string;
  price: string;
};

export const SearchResult: FC<Props> = ({productId, ownerId, name, owner, price}) => {
  const productImage = useRecoilValue(productFirstProductPhotoSelector(productId));
  const router = useIonRouter();

  return (
    <IonList>
      <IonItem className='ion-item-search' lines='full'>
        <div className='w-full flex space-x-2' onClick={() => {
                router.push(`/details/${productId}`);
              }}>
          {productImage ? (
            <IonThumbnail className='ion-thumbnail'>
              <img alt='productImage' src={productImage} />
            </IonThumbnail>
          ) : (
            <IonThumbnail className='ion-thumbnail'>
              <img
                alt='placeholder'
                src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
              />
            </IonThumbnail>
          )}
          <div
            className='w-full flex flex-col font-medium -mt-1 tablet:-mt-1.5
            tablet:text-lg desktop:text-xl'
          >
            <div className='line-clamp-1'>{name}</div>
            <div className='font-light'>{owner}</div>
            <div>{price}€</div>
          </div>
        </div>
      </IonItem>
    </IonList>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {defineCustomElements} from '@ionic/pwa-elements/loader';

import './tailwindcss.css';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

export const stripePromise = loadStripe(
  `pk_live_51PErdaIlrnWDASyPUkGraR9F7KfyDkz4KfLD
  yppWB56NGFUsokA4y410MgV6IgPopEW6le2MqBvMl1Kxmrl3CuTF00TcK56Tjl`,
);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

import React, {FC} from 'react';
import {IonButton, IonCard, IonIcon, IonText, useIonRouter} from '@ionic/react';
import {Chat} from '../../interfaces/Chat';
import {
  MAX_MESSAGE_PREVIEW_LENGTH_AT_INBOX_ITEM,
  MAX_USER_NAME_LENGTH_AT_INBOX_ITEM,
} from '../../utils/constants';
import {chevronForward} from 'ionicons/icons';

type InboxItemProps = {} & Chat;

export const InboxItem: FC<InboxItemProps> = ({
  hasNewMessages,
  interlocutor,
  product,
  lastMessageDate,
  lastMessage,
  isLastMessageSentByCurrentUser,
}) => {
  const router = useIonRouter();

  const getShortenedText = (text: string, maxLength: number) => {
    if (!text) {
      return;
    }

    if (text.length >= maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const getFormattedDate = () => {
    if (lastMessageDate == null) {
      return;
    }

    return lastMessageDate.toLocaleDateString('pl-pl', {
      day: 'numeric',
      month: '2-digit',
    });
  };

  const redirectToConversation = () => {
    router.push(`/conversation/${interlocutor.id}/${product.id}`, 'forward');
  };

  return (
    <IonCard
      onClick={redirectToConversation}
      className='h-18 tablet:h-20 desktop:h-24 bg-red-50 flex rounded-lg'
    >
        <div
          style={{backgroundImage: `url(${interlocutor.imageUrl})`}}
          className={`w-1/4 h-full flex flex-shrink-0 bg-contain bg-center bg-no-repeat`}
        />
      <div className='px-3 h-full w-full flex flex-col space-y-2'>
        <IonText className='font-medium text-sm tablet:text-base desktop:text-lg'>
          {getShortenedText(
            interlocutor.firstName + ' ' + interlocutor.lastName,
            MAX_USER_NAME_LENGTH_AT_INBOX_ITEM,
          )}
        </IonText>
        {lastMessage && (
          <div
            className='w-full flex justify-between items-center
            text-xs tablet:text-sm desktop:text-base'
          >
            <IonText
              className={
                `break-words` + (hasNewMessages && !isLastMessageSentByCurrentUser && 'font-bold')
              }
            >
              {getShortenedText(lastMessage, MAX_MESSAGE_PREVIEW_LENGTH_AT_INBOX_ITEM)}
            </IonText>
            <IonText>{getFormattedDate()}</IonText>
          </div>
        )}
      </div>
      <IonButton className='bg-primary h-full w-2/12 m-auto'>
        <IonIcon color='light' size='large' icon={chevronForward} />
      </IonButton>
    </IonCard>
  );
};

import React from 'react';
import {useRecoilValue} from 'recoil';
import ProductsList from '../Products.list';
import {getOwnerProductsSelector} from '../../../selectors/productsSelectors';

const AllSellerProductsList = () => {
    const products = useRecoilValue(getOwnerProductsSelector);

  return <ProductsList products={products!} />;
};

export default AllSellerProductsList;

import React from 'react';
import logoText from '../static/logo_text.svg';

interface AppTitleProps {
  width?: number;
  height?: number;
}

const LogoText: React.FC<AppTitleProps> = ({width = 230, height}) => {
  return <img alt='application title' src={logoText} width={width} height={height} />;
};

export default LogoText;

import React, {FC, Suspense, useEffect, useRef, useState} from 'react';
import {IonAvatar, IonContent, IonHeader, IonImg, IonLabel, IonPage, IonTitle} from '@ionic/react';
import {useParams} from 'react-router';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {isBottomTabBarHiddenAtom} from '../../atoms/bottomTabBarAtom';
import {Message} from '../../interfaces/Message';
import {MessageItem} from '../../components/Chat/Message';
import {messagesService} from '../../services/messagesService';
import {ConversationProductInfo} from '../../components/Chat/ConversationProductInfo';
import {ConversationInterlocutorInfo} from '../../components/Chat/ConversationInterlocutorInfo';
import {lastestMessageIdFromDbAtom} from '../../selectors/messagesSelectors';
import {SendMessageInput} from '../../components/Chat/SendMessageInput';
import {BASE_STORAGE_URL} from '../../utils/constants';
import {supabase} from '../../supabaseClient';
import {ConversationProfilePreview} from '../../interfaces/ConversationProfilePreview';
import {BackButton} from '../../components/Buttons/BackButton';
import {deviceWidthType} from '../../atoms/deviceWidthType';
import AppToolbar from '../../components/AppToolbar';

export interface ConversationParams {
  interlocutorId?: string;
  productId?: string;
}

export const Conversation: FC = () => {
  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);
  const {interlocutorId, productId} = useParams<ConversationParams>();
  const [userData, setUserData] = useState<ConversationProfilePreview>();
  const [messages, setMessages] = useState<Message[]>([]);
  const lastestMessageIdFromDb = useRecoilValue(lastestMessageIdFromDbAtom);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const deviceWidth = useRecoilValue(deviceWidthType);
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    const fetchData = async () => {
      {
        const {data, error} = await supabase
          .from('profiles')
          .select('avatar_url, first_name, last_name')
          .eq('user_id', interlocutorId)
          .single();
        if (error) {
          throw error.message;
        }
        setUserData({
          avatarUrl: data.avatar_url && `${BASE_STORAGE_URL}/avatars/${data.avatar_url}`,
          fullName: `${data.first_name && data.first_name} ${data.last_name && data.last_name}`,
        });
      }
    };

    const calculateHeight = () => {
      if (window.innerWidth < 900) {
        setHeight(Math.trunc(window.innerHeight - 283));
      } else {
        setHeight(Math.trunc(window.innerHeight - 345));
      }
    };

    fetchData();
    calculateHeight();
  }, []);

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  useEffect(() => {
    messagesService
      .getMessages(interlocutorId, Number(productId))
      .then(setMessages)
      .then(scrollLastMessageIntoView);
  }, [lastestMessageIdFromDb]);

  const sendMessage = async (message: string) => {
    await messagesService.sendMessage(interlocutorId!, Number(productId), message);
  };

  const scrollLastMessageIntoView = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          centerChild={
            <>
              {deviceWidth === 'mobile' ? (
                <IonTitle className='font-normal tablet:text-xl desktop:text-xl'>
                  {userData?.fullName}
                </IonTitle>
              ) : (
                <div
                  className='tablet:w-[350px] tablet:h-[72px] desktop:w-[400px] desktop:h-[92px]
                  mx-auto flex items-center bg-darkGreen rounded-full'
                >
                  <IonAvatar
                    className='tablet:w-[70px] tablet:h-[66px] desktop:w-[90px] desktop:h-[82px]
                ml-1.5'
                  >
                    <IonImg
                      alt='profile avatar'
                      src={userData?.avatarUrl}
                      className='flex-shrink-0 bg-contain bg-center bg-no-repeat'
                    />
                  </IonAvatar>
                  <IonLabel className='m-auto text-white tablet:text-xl desktop:text-xl'>
                    {userData?.fullName}
                  </IonLabel>
                </div>
              )}
            </>
          }
        />
      </IonHeader>
      <IonContent scrollY={false} className='relative'>
        <Suspense fallback={null}>
          <ConversationProductInfo productId={productId!} />
          {deviceWidth === 'mobile' && (
            <ConversationInterlocutorInfo
              fullName={userData?.fullName}
              avatarUrl={userData?.avatarUrl}
            />
          )}
        </Suspense>
        <div style={{height}}>
          <div className='h-full overflow-y-auto'>
            {messages && messages.map((x) => <MessageItem key={x.id} {...x} />)}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className='w-full flex absolute bottom-6'>
          <SendMessageInput onMessageSent={sendMessage} />
        </div>
      </IonContent>
    </IonPage>
  );
};

import React, {FC} from 'react';
import {IonContent, IonHeader, IonPage} from '@ionic/react';
import {SearchButton} from '../../../components/Buttons/SearchButton';
import {CartButton} from '../../../components/Buttons/CartButton';
import IonLoadingSuspense from '../../../components/IonLoadingSuspense';
import AllSellerProductsList from './AllSellerProducts.list';
import FilterDetailModal from '../../../components/Filter/FilterDetailModal';
import FilterModal from '../../../components/Filter/FIlterModal';
import {BackButton} from '../../../components/Buttons/BackButton';
import AppToolbar from '../../../components/AppToolbar';

const AllSellerProducts: FC = () => (
  <IonPage>
    <IonHeader>
      <AppToolbar
        leftSlot1={<BackButton />}
        rightSlot1={<SearchButton />}
        rightSlot2={<CartButton />}
      />
    </IonHeader>
    <IonContent>
      <IonLoadingSuspense>
        <AllSellerProductsList />
      </IonLoadingSuspense>
      <FilterDetailModal />
      <IonLoadingSuspense>
        <FilterModal />
      </IonLoadingSuspense>
    </IonContent>
  </IonPage>
);

export default AllSellerProducts;

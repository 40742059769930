import React, {Fragment} from 'react';
import {useForm} from 'react-hook-form';
import {FormField} from '../interfaces/FormField';
import Button from './Buttons/Button';

type BIonFormProps = {
  fields: FormField[];
  onSubmit: (data: any) => void;
  submitBtnText?: string;
  submitButtonTopMargin?: number;
  customCss?: string;
  defaultValues?: any;
  type?: 'default' | 'login';
};

const BIonForm: React.FC<BIonFormProps> = ({
  fields,
  onSubmit,
  submitBtnText = 'Speichern',
  children,
  customCss,
  defaultValues = {},
  type = 'default',
}) => {
  const {handleSubmit, control} = useForm({defaultValues});

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={customCss}>
      <div>
        {fields.map(({component, name, options}) => (
          <Fragment key={name}>
            {React.cloneElement(component, {
              fieldName: name,
              control,
              rules: options,
            })}
          </Fragment>
        ))}
      </div>
      {type === 'login' && (
        <div className='flex justify-center'>
          <input type='checkbox' defaultChecked={true} />
          <p className='pl-2'>Bleib auf diesem Gerät angemeldet</p>
        </div>
      )}
      <div className='flex justify-center'>
        {children ?? <Button text={submitBtnText} type='submit' />}
      </div>
    </form>
  );
};

export default BIonForm;

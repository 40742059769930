import React, {FC} from 'react';
import BIonForm from '../components/BIonForm';
import TextInput from '../components/TextInput/TextInput';
import BaseFormProps from '../interfaces/BaseFormProps';
import {FormField} from '../interfaces/FormField';
import {RecoverPasswordData} from '../interfaces/RecoverPasswordData';
import regularExpressions from '../utils/regularExpressions';

const RecoverPasswordForm: FC<BaseFormProps<RecoverPasswordData>> = ({onSubmit}) => {
  const fields: FormField[] = [
    {
      name: 'email',
      component: <TextInput label='Email' />,
      options: {
        required: 'This field is required!',
        pattern: {
          message: 'Die eingegebene E-Mail-Adresse ist falsch',
          value: regularExpressions.email,
        },
      },
    },
  ];

  return (
    <BIonForm
      fields={fields}
      submitBtnText='Senden'
      onSubmit={onSubmit}
      customCss='mx-auto w-full tablet:w-3/5 desktop:w-3/5'
    />
  );
};

export default RecoverPasswordForm;

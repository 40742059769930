import React, {FC, useEffect} from 'react';
import {IonContent, IonHeader, IonPage} from '@ionic/react';
import IonLoadingSuspense from '../../../components/IonLoadingSuspense';
import {useSetRecoilState} from 'recoil';
import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';
import FilterModal from '../../../components/Filter/FIlterModal';
import FilterDetailModal from '../../../components/Filter/FilterDetailModal';
import {MyProductsList} from './MyProducts.list';
import MyProductsHeaderSubcategories from './MyProductsHeaderSubcategories.element';
import {CartButton} from '../../../components/Buttons/CartButton';
import {SearchButton} from '../../../components/Buttons/SearchButton';
import FilterButton from '../../../components/Buttons/FilterButton';
import AppToolbar from '../../../components/AppToolbar';
import {BackButton} from '../../../components/Buttons/BackButton';

const MyProducts: FC = () => {
  const setChoosenSubCategoryId = useSetRecoilState(choosenSubcategoryIdAtom);

  useEffect(() => {
    return () => {
      setChoosenSubCategoryId(null);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          leftSlot2={<FilterButton />}
          rightSlot1={<SearchButton />}
          rightSlot2={<CartButton />}
        />
        <MyProductsHeaderSubcategories />
      </IonHeader>
      <IonContent>
        <IonLoadingSuspense>
          <MyProductsList />
        </IonLoadingSuspense>
        <FilterDetailModal />
        <IonLoadingSuspense>
          <FilterModal />
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};

export default MyProducts;

import {IonImg, IonText} from '@ionic/react';
import React, {FC} from 'react';
import {ConversationProfilePreview} from '../../interfaces/ConversationProfilePreview';
import {MAX_INTERLOCUTOR_FULLNAME_AT_CONVERSATION} from '../../utils/constants';

type ConversationInterlocutorInfoProps = ConversationProfilePreview;

export const ConversationInterlocutorInfo: FC<ConversationInterlocutorInfoProps> = ({
  avatarUrl,
  fullName,
}) => {
  const shortenedFullName = () => {
    if (fullName?.length! >= MAX_INTERLOCUTOR_FULLNAME_AT_CONVERSATION) {
      return fullName?.slice(0, MAX_INTERLOCUTOR_FULLNAME_AT_CONVERSATION);
    }
    return fullName;
  };

  return (
    <div
      className='flex flex-col w-full items-center justify-center border-b-1 border-primary/50
      h-20'
    >
      <IonImg className='w-12 h-12 text-tinier' src={avatarUrl} alt='profile avatar' />
      <IonText className='text-xs pb-2 pt-0'>{shortenedFullName()}</IonText>
    </div>
  );
};

import {IonContent, IonHeader, IonLoading, IonPage} from '@ionic/react';
import React, {Suspense} from 'react';
import AppToolbar from '../../components/AppToolbar';
import ButtonLink from '../../components/Buttons/ButtonLink';

const ProfileTab = () => {
  return (
    <IonPage>
      <IonHeader>
        <AppToolbar />
      </IonHeader>
      <IonContent>
        <Suspense fallback={<IonLoading isOpen={false} />}>
          <div className='flex flex-col pt-6 gap-5 items-center'>
            <ButtonLink
              routerLink='/history'
              routerDirection='forward'
              text='Bereits gekauft/verkauft'
            />
            <ButtonLink
              className='w-[80%] tablet:w-[55%] desktop:w-[35%] normal-case h-12 bg-primary
                          rounded-xl shadow-md text-white'
              routerLink='/add'
              routerDirection='forward'
              text='Produkt hinzufügen'
            />
            <ButtonLink routerLink='/sale' routerDirection='forward' text='Verkauf' />
          </div>
        </Suspense>
      </IonContent>
    </IonPage>
  );
};
export default ProfileTab;

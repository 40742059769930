import {selector, selectorFamily} from 'recoil';
import {choosenCategoryIdAtom} from '../atoms/choosenCategoryIdAtom';
import {categories} from '../atoms/selectProductAtom';
import {Subcategory} from '../interfaces/Subcategory';
import {supabase} from '../supabaseClient';

export const subcategoriesOfChoosenCategorySelector = selector({
  key: 'subcategoriesOfChoosenCategory',
  get: async ({get}) => {
    let choosenCategoryId = get(choosenCategoryIdAtom);
    if (choosenCategoryId == null) {
      const {data: categoriesList} = get(categories);
      if (categoriesList == null || categoriesList.length === 0) {
        return;
      }
      choosenCategoryId = categoriesList[0].id as number;
    }
    const {data, error} = await supabase
      .from<Subcategory>('subcategories')
      .select('id,name,category')
      .eq('category', choosenCategoryId);
    if (error != null) {
      throw error.message;
    }
    if (data == null) {
      return;
    }
    return data;
  },
});

export const subcategoriesSelector = selectorFamily({
  key: 'subcategories',
  get: (categoryId) => async () => {
    if (categoryId == null) {
      return;
    }
    const {data, error} = await supabase
      .from('subcategories')
      .select('id,name,category')
      .eq('category', categoryId);
    if (error != null) {
      throw error.message;
    }
    if (data == null) {
      return;
    }
    return data;
  },
});

export const allSubcategoriesSelector = selector({
  key: 'allSubcategoriesSelector',
  get: async () => {
    const {data, error} = await supabase.from('subcategories')
    .select('id,name,category')
    .order('name', {ascending: true});
    if (error != null) {
      throw error.message;
    }
    if (data == null) {
      return;
    }
    return data as Subcategory[];
  },
});

import React, {useRef, useState} from 'react';
import {IonContent, IonModal} from '@ionic/react';
import {SliderData} from '../../interfaces/SliderData';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

export const SliderImages = ({imagesBlobs}: SliderData) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  function confirm() {
    modal.current?.dismiss();
  }

  return (
    <IonContent className='h-96'>
      <Swiper id='open-modal' className='h-full'>
        {imagesBlobs &&
          imagesBlobs.map((imageBlob: SliderData, index: number) => {
            return (
              <SwiperSlide key={imageBlob[1]} onClick={() => setCurrentImageIndex(index)}>
                <div
                  className='bg-center bg-cover bg-no-repeat h-full w-full
                             tablet:bg-contain desktop:bg-contain'
                  style={{backgroundImage: `url(${URL.createObjectURL(imageBlob[0].data)})`}}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <IonModal ref={modal} trigger='open-modal'>
        <IonContent className='ion-padding' onClick={() => confirm()}>
          <div className='h-full w-full flex items-center justify-center'>
            <img
              src={URL.createObjectURL(imagesBlobs[currentImageIndex][0].data)}
              alt='fullscreen'
            />
          </div>
        </IonContent>
      </IonModal>
    </IonContent>
  );
};

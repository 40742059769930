import React, {useEffect, useState} from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonRouterLink,
  IonSlide,
  useIonRouter,
} from '@ionic/react';
import {chevronForwardOutline, createOutline} from 'ionicons/icons';
import {useParams} from 'react-router';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {currentPathUrl, currentBlobImages} from '../../../atoms/currentProductAtom';
import {currentUserProfile} from '../../../atoms/currentUserProfileAtom';
import {SliderImages} from '../../../components/Slider/Slider';
import {useAuth} from '../../../contexts/Auth';
import EditPage from '../EditProduct/Edit.page';
import {cartProductsQuantity, getCartProducts} from '../../../selectors/cartSelector';
import cartsService from '../../../services/cartsService';
import {CartButton} from '../../../components/Buttons/CartButton';
import {FavoritesButton} from '../../../components/Buttons/FavouriteButton';
import {getUserFavoritesProductsSelector} from '../../../selectors/favoritesSelector';
import {BackButton} from '../../../components/Buttons/BackButton';
import AppToolbar from '../../../components/AppToolbar';
import {ownerProductsAtom} from '../../../atoms/ownerProductsAtom';
import {getProductDetailsTEXT} from '../../../selectors/productsSelectors';

const DetailsProductInfo = () => {
  const {user} = useAuth();
  const {productId}: any = useParams();
  const router = useIonRouter();

  const [isOwnerUser, setIsOwnerUser] = useState<boolean>(false);
  const [isInCart, setIsInCart] = useState<boolean>(false);
  const [editProduct, setEditProduct] = useState<boolean>(false);

  const productDetailsInfo = useRecoilValue(getProductDetailsTEXT(productId));
  const ownerId = productDetailsInfo[0].user_id;
  const ownerInfo = useRecoilValue(currentUserProfile(ownerId));
  const imageUrl = useRecoilValue(currentPathUrl(productId));
  const imagesBlobs = useRecoilValue(currentBlobImages(imageUrl));
  const cartProducts = useRecoilValue(getCartProducts);
  const favorites = useRecoilValue(getUserFavoritesProductsSelector);
  const setCartProductsQuantity = useSetRecoilState(cartProductsQuantity);
  const setOwnerId = useSetRecoilState(ownerProductsAtom);

  useEffect(() => {
    if (user?.id == ownerId) setIsOwnerUser(true);
    setOwnerId(ownerId);

    if (cartProducts.some((x) => x.id == productId)) {
      setIsInCart(true);
      return;
    }
    setIsInCart(false);
  }, []);

  const addToCart = async () => {
    if (!user) {
      router.push('/cart');
      return;
    }
    if (!isInCart) {
      try {
        await cartsService.insertProductToCurrentUserCart(productId);
        setIsInCart(true);
        setCartProductsQuantity((prev) => prev + 1);
      } catch (error) {
        setIsInCart(false);
        setCartProductsQuantity((prev) => prev - 1);
      }
      return;
    }
    await cartsService.removeProductFromCurrentUserCart(productId);
    setIsInCart(false);
    setCartProductsQuantity((prev) => prev - 1);
  };

  return (
    <>
      {editProduct ? (
        <EditPage />
      ) : (
        <>
          <IonHeader>
            <AppToolbar
              leftSlot1={<BackButton />}
              rightSlot1={
                isOwnerUser && (
                  <IonIcon
                    className={'text-white text-2xl tablet:text-2xl desktop:text-3xl pr-2'}
                    icon={createOutline}
                    onClick={() => setEditProduct(true)}
                  />
                )
              }
              rightSlot2={<CartButton />}
            />
          </IonHeader>
          <IonContent>
            <div className='h-full'>
              <div
                className='flex items-center h-24 w-full tablet:w-[75%] desktop:w-[60%]
                              mx-auto bg-white'
              >
                <img
                  className='h-24 w-24 bg-white '
                  src={ownerInfo?.avatar && URL.createObjectURL(ownerInfo.avatar?.file)}
                  alt={'avatar'}
                />
                <div
                  className='bg-white text-darkGray text-sm w-screen flex flex-col
                justify-center space-y-1 pl-3'
                >
                  <p>
                    Nick Name: {ownerInfo.nick_name}
                  </p>
                </div>
                <div className='flex justify-center items-center bg-primary w-[10%] h-24 text-2xl'>
                  <IonRouterLink routerLink='/seller-products'>
                    <IonIcon className='text-white' icon={chevronForwardOutline} />
                  </IonRouterLink>
                </div>
              </div>
              <div
                className='mx-auto tablet:w-[75%] desktop:w-[60%]
                              border-b-2 border-gray-400'
              ></div>
              <div
                className='w-full desktop:h-full mx-auto mobile:h-[90%]
              tablet:w-[75%] desktop:w-[60%] bg-darkGreen bg-opacity-20'
              >
                {isOwnerUser ? (
                  <></>
                ) : (
                  <FavoritesButton
                    productId={productId}
                    isFavorited={favorites?.some((x) => x.productId == productId)!}
                    className='absolute right-1 top-1 text-2xl tablet:text-3xl desktop:text-4xl
                  text-primary z-10'
                  />
                )}
                {imagesBlobs[0] ? (
                  <SliderImages imagesBlobs={imagesBlobs} />
                ) : (
                  <IonSlide>
                    <div
                      className='bg-center bg-no-repeat h-96 w-full'
                      style={{
                        // eslint-disable-next-line max-len
                        backgroundImage: `url('https://ionicframework.com/docs/img/demos/thumbnail.svg')`,
                      }}
                    />
                  </IonSlide>
                )}
                <div
                  className='bg-darkGray px-3 h-10 flex items-center justify-around -mb-0.5
                mobile:justify-around text-sm'
                >
                  <div className='flex'>
                    <div className='font-thin'>Größe: </div>
                    <div className='pl-1'>
                      {productDetailsInfo[0].sizes.map((size: string) => size).join(', ')}
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='font-thin'>Preis:</div>
                    <div className='pl-1'>{productDetailsInfo[0].price}€</div>
                  </div>
                  <div>{productDetailsInfo[0].subcategory.name}</div>
                </div>
                <div className='px-12 pt-6 font-semibold'>
                  Beschreibung:
                  <div className='font-normal pt-2'>{productDetailsInfo[0].description}</div>
                </div>
                {!isOwnerUser && (
                  <div
                    className='flex flex-col justify-center items-center
                                  space-y-2 mb-6 px-3 py-6'
                  >
                    <IonButton
                      fill='clear'
                      onClick={() =>
                        router.push(`/conversation/${ownerId}/${productId}`, 'forward')
                      }
                      className='w-80 mobile:max-w-[90%] h-12 border rounded-xl text-white text-sm
                  normal-case font-normal'
                    >
                      Nachricht an Verkäufer
                    </IonButton>
                    <IonButton
                      fill='clear'
                      onClick={addToCart}
                      className={`w-80 mobile:max-w-[90%] h-12 bg-lightGreen rounded-xl text-sm
                  normal-case
                  font-normal text-white ${isInCart && 'text-pureRed'}`}
                    >
                      {isInCart ? 'Aus dem Warenkorb entfernen' : 'In den Warenkorb legen'}
                    </IonButton>
                  </div>
                )}
              </div>
            </div>
          </IonContent>
        </>
      )}
    </>
  );
};
export default DetailsProductInfo;

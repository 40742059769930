import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {getSoldProductsSelector} from '../../../../selectors/historyProductsSelectors';
import SoldProductElement from './SoldProduct.element';

const SoldProductsTab: FC = () => {
  const currentUserSoldProducts = useRecoilValue(getSoldProductsSelector);

  return (
    <>
      {currentUserSoldProducts.map((x: any) => (
        <div key={x.productId}>
          <SoldProductElement {...x} />
        </div>
      ))}
    </>
  );
};

export default SoldProductsTab;

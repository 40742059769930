import {selectorFamily} from 'recoil';
import {supabase} from '../supabaseClient';

export const filterSelector = selectorFamily({
  key: 'sizes',
  get: (type: string) => async () => {
    const {data, error} = await supabase.from(`${type}s`).select('id,name');

    if (error != null) {
      throw error.message;
    }

    return data;
  },
});

import {selector, selectorFamily} from 'recoil';
import {supabase} from '../supabaseClient';

export const categoriesSelector = selector({
  key: 'catergoriesSelector',
  get: async () => {
    const {data, error} = await supabase.from('categories').select('*');

    if (error != null) {
      throw error.message;
    }
    return data;
  },
});

const categoryImageSelector = selectorFamily<string, string>({
  key: 'categoryImage',
  get: (imageUrl: string) => async () => {
    const {data, error} = await supabase.storage.from('categories').getPublicUrl(imageUrl);

    if (error != null) {
      throw error.message;
    }
    return data!.publicURL;
  },
});

export const categoriesWithImagesSelector = selector({
  key: 'categoriesWithImages',
  get: async ({get}) => {
    const categories = get(categoriesSelector);

    const catWithImages = categories.map((cat) => {
      return {
        ...cat,
        image_url: get(categoryImageSelector(cat.image_url!)),
      };
    });

    return catWithImages;
  },
});

import React from 'react';
import {IonContent, IonHeader, IonPage} from '@ionic/react';
import {supabase} from '../../supabaseClient';
import useToast from '../../hooks/customToast';
import ChangePasswordData from '../../interfaces/ChangePasswordData';
import ChangePasswordForm from '../../forms/EditProfile/ChangePassword.form';
import {BackButton} from '../../components/Buttons/BackButton';
import AppToolbar from '../../components/AppToolbar';

const ChangePassword = () => {
  const presentToast = useToast();

  const updatePassword = async (password: ChangePasswordData) => {
    const {error} = await supabase.auth.update(password);
    if (error) {
      await presentToast(error.message, 'error');
      return;
    }
    await presentToast('Passwort erfolgreich geändert!');
  };
  return (
    <IonPage>
      <IonHeader>
        <AppToolbar leftSlot1={<BackButton />} titleText='Passwort ändern' />
      </IonHeader>
      <IonContent>
        <ChangePasswordForm onSubmit={updatePassword} />
      </IonContent>
    </IonPage>
  );
};
export default ChangePassword;

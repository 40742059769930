import React, {FC, Suspense, useEffect} from 'react';
import {IonBadge, IonButton, IonIcon, useIonRouter} from '@ionic/react';
import {
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  cartProductsQuantity,
  cartProductsQuantitySelector,
  getCartProducts,
} from '../../selectors/cartSelector';
import cart from '../../static/cart.svg';
import {useAuth} from '../../contexts/Auth';
import {
  tmpCartActionsCount,
  tmpCartProductsQuantity,
  tmpCartProductsQuantitySelector,
} from '../../atoms/tmpCartSelector';

export const CartButton: FC = () => {
  const router = useIonRouter();
  const refresher = useRecoilRefresher(getCartProducts);
  const cartProducts = useRecoilValue(cartProductsQuantity);

  const handleGoToCart = () => {
    router.push('/cart');
    refresher();
  };

  return (
    <IonButton onClick={handleGoToCart}>
      <IonIcon className='text-white text-2xl tablet:text-3xl desktop:text-4xl' icon={cart} />
      <Suspense
        fallback={
          <IonBadge mode='ios' className='text-white -ml-3 mt-5 bg-transparent'>
            {cartProducts}
          </IonBadge>
        }
      >
        <CartBadge />
      </Suspense>
    </IonButton>
  );
};

const CartBadge: FC = () => {
  const {user} = useAuth();

  const products = useRecoilValue(cartProductsQuantitySelector);
  const [cartProducts, setCartProductsQuantity] = useRecoilState(cartProductsQuantity);
  // const setCartActionsCount = useSetRecoilState(cartActionsCount);

  const tmpProducts = useRecoilValue(tmpCartProductsQuantitySelector);
  const [tmpCartProducts, setTmpCartProducts] = useRecoilState(tmpCartProductsQuantity);
  const setTmpCartActionsCount = useSetRecoilState(tmpCartActionsCount);

  useEffect(() => {
    setTmpCartProducts(tmpProducts);
  }, [tmpProducts]);

  useEffect(() => {
    setCartProductsQuantity(products);
  }, [products]);

  useEffect(() => {
    // setCartActionsCount((prev) => prev + 1);
    // For some reason it affects DetailsProductInfo.page SliderImages component
    // if cart badge ever stops working try uncommenting this and const above
    setTmpCartActionsCount((prev) => prev + 1);
  }, []);

  return (
    <>
      <IonBadge mode='ios' className='text-white -ml-3 mt-5 bg-transparent'>
        {user ? cartProducts : tmpCartProducts}
      </IonBadge>
    </>
  );
};

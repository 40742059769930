import React, {FC, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {getUserProductsSelector, loggedUserProducts} from '../../../selectors/productsSelectors';
import ProductsList from '../Products.list';
import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';

export const MyProductsList: FC = () => {
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const products = useRecoilValue(loggedUserProducts);
  const allProducts = useRecoilValue(getUserProductsSelector);
  const subcategory = useRecoilValue(choosenSubcategoryIdAtom);

  useEffect(() => {
    if (subcategory === 46) {
      setSelectedProducts(allProducts);
      return;
    }
    setSelectedProducts(products);
  }, [subcategory]);

  return <ProductsList products={selectedProducts!} />;
};

import React, {useEffect} from 'react';
import {IonItem, IonLabel, IonNote, IonSelect, IonSelectOption} from '@ionic/react';
import {ComponentProps, FC} from 'react';
import {useController} from 'react-hook-form';
import {BaseInputProps} from '../../interfaces/BaseInputsProps';
import {currentCategoryId} from '../../atoms/categoryValueAtom';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {previewProductDataAtom} from '../../atoms/previewProductDataAtom';
import {supabase} from '../../supabaseClient';

type SelectProps = {
  label: string;
  type?: 'text' | 'password';
  item: any;
} & BaseInputProps &
  ComponentProps<typeof IonSelect>;

const SelectCategories: FC<SelectProps> = ({
  fieldName = '',
  label,
  control,
  rules,
  item,
}: SelectProps) => {
  const {
    field: {name, onChange, onBlur, ref, value},
    fieldState: {error},
  } = useController({
    control,
    name: fieldName,
    rules,
  });

  const [previewData, setPreviewData] = useRecoilState(previewProductDataAtom);
  const categoryId = useSetRecoilState(currentCategoryId);

  useEffect(() => {
    categoryId(value);

    if (value === undefined) return;

    const fetchData = async () => {
      const {data, error} = await supabase
        .from('categories')
        .select('name')
        .eq('id', value)
        .single();

      if (error) {
        throw error.message;
      }

      setPreviewData({...previewData, [name]: data});
    };

    fetchData();
  }, [value]);

  return (
    <IonItem
      lines='none'
      className='my-3 mx-auto w-11/12 tablet:w-2/3 desktop:w-2/5 bg-offWhite bg-opacity-25
        rounded-3xl ion-invalid'
    >
      <IonLabel position='floating'>{label}</IonLabel>
      <IonSelect
        ref={ref}
        name={name}
        value={value}
        okText='Okay'
        cancelText='Dismiss'
        onIonChange={onChange}
        onIonBlur={onBlur}
        interface='popover'
      >
        {item.map((items: any, numberId: number) => (
          <IonSelectOption key={numberId} value={items.id} className='bg-darkGreen'>
            {items.name}
          </IonSelectOption>
        ))}
      </IonSelect>
      <IonNote slot='error' color='danger' className='text-sm tablet:text-base desktop:text-lg'>
        {error && error.message}
      </IonNote>
    </IonItem>
  );
};

export default SelectCategories;
